<template>
  <div>
    <div v-for="(n,i) in data" :key="i" class="border border-gray-lightest rounded-lg p-3.5 mb-6">
      <div class="flex gap-x-6 gap-y-3.5" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row justify-between'">
        <div class="flex items-center">
          <span class="text-lg icon-file-text text-gray" :class="{ 'mr-6': !submitted, 'mr-3.5': submitted}"></span>
          <div>
            <div>
              <h5 v-if="n.isLocalPartner" class="text-sm text-gray"><span class="text-tertiary">Local Partner #{{ n.sequence }}</span> of {{ n.vendorPrincipal }}</h5>
              <div v-else class="text-sm text-tertiary">Vendor #{{ n.sequence }}</div>
            </div>
            <div>
              <h5 class="text-gray-dark">{{ n.vendorName }}</h5>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-3.5">
          <ButtonGista
            @click="clickViewLoi"
            type="secondary"
            color="success-dark"
            size="small"
            v-if="submitted"
            :class="projectDetailContainerWidth < 640 ? 'w-full' : ''"
            >
            View LOI
            <template #icon-left>
              <span class="mr-2.5 text-lg icon-eye text-success"></span>
            </template>
          </ButtonGista>
          <p :class="!n.collapse ? 'rotate-180' : 'rotate-0'" @click="changeCollapse(i)"
					  class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
        </div>
      </div>
      <div v-if="!n.collapse">
        <div class="grid gap-x-6 gap-y-3.5 my-3.5"
          :class="{
            'grid-cols-1': projectDetailContainerWidth < 768,
            'grid-cols-2': projectDetailContainerWidth >= 768
        }">
          <InputDisabled 
            label="Vendor Name" 
            :value="n.vendorName" 
            :class="{
              'col-span-2': projectDetailContainerWidth < 768,
              'col-span-1': projectDetailContainerWidth >= 768
            }"
          />
          <InputDisabled 
            label="Status"
            :value="n.status"
            :class="{
              'col-span-2': projectDetailContainerWidth < 768,
              'col-span-1': projectDetailContainerWidth >= 768
            }"
          />
          <InputDisabled 
            label="LOI No."
            :value="n.loiNo"
            :class="{
              'col-span-2': projectDetailContainerWidth < 768,
              'col-span-1': projectDetailContainerWidth >= 768
            }"
          />
          <InputDisabled 
            label="LOI Date"
            :value="n.loiDate"
            :class="{
              'col-span-2': projectDetailContainerWidth < 768,
              'col-span-1': projectDetailContainerWidth >= 768
            }"
          />
          <InputDisabled 
            label="Offer No."
            :value="n.offerNo"
            :class="{
              'col-span-2': projectDetailContainerWidth < 768,
              'col-span-1': projectDetailContainerWidth >= 768
            }"
          />
          <InputDisabled 
            label="Offer Date"
            :value="n.offerDate"
            :class="{
              'col-span-2': projectDetailContainerWidth < 768,
              'col-span-1': projectDetailContainerWidth >= 768
            }"
          />
          <InputDisabled 
            label="Final Negotiation Date"
            :value="n.finalNegotiationDate"
            :class="{
              'col-span-2': projectDetailContainerWidth < 768,
              'col-span-1': projectDetailContainerWidth >= 768
            }"
          />
          <div class="col-span-2 pt-3.5 border-t border-gray-lightest">
            <TableLoi />
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Term of Payment
              </label>
            </div>
            <div class="">
              <TiptapView v-if="n.termOfPayment" v-model="n.termOfPayment" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Note Negotiation / BCA Request
              </label>
            </div>
            <div class="">
              <TiptapView v-if="n.noteNegotiation" v-model="n.noteNegotiation" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div v-if="false">
            <ValidationProvider 
              :name="`picVendor`"
              rules="required"
              v-slot="{ errors }">
              <InputSolutip 
                type="text"
                label="PIC Vendor"
                size="large"
                :mandatory="true"
                v-model="n.picVendor"
                :error="errors[0]">
                <template #message>
                  <p>{{ errors[0] }}</p>
                </template>
              </InputSolutip>
            </ValidationProvider>
          </div>
          <div v-if="true">
            <InputDisabled 
              label="PIC Vendor"
              :value="n.picVendor"
            />
          </div>
        </div>
        <div v-if="!submitted" class="flex justify-end border-t border-gray-lightest pt-3.5">
          <ButtonGista type="secondary" color="primary" @click="clickPreviewLoi">Preview LOI</ButtonGista>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import InputSolutip from '@/core/components/custom/Input.vue'
import TiptapView from '@/core/components/custom/TiptapView.vue'
import TableLoi from '@/core/components/rfp/sktLoi/TableLoi.vue'

export default {
  name: "CardVendorSkt",
  props: ['submitted'],
  data() {
    return {
      data: [
        {
          sequence: 1,
          isLocalPartner: true,
          vendorPrincipal: 'PT. QWE',
          vendorName: 'PT. ABC',
          status: 'Terpilih',
          loiNo: 'n/a',
          loiDate: 'n/a',
          offerNo: 'ABC/001/2023',
          offerDate: '01 Januari 2023',
          finalNegotiationDate: '05 Januari 2023',
          termOfPayment: 'term of payment',
          noteNegotiation: 'note negotiation',
          picVendor: 'Budi',
          collapse: true,
        },
        {
          sequence: 2,
          isLocalPartner: false,
          vendorPrincipal: '',
          vendorName: 'PT. DEF',
          status: 'Terpilih',
          loiNo: 'n/a',
          loiDate: 'n/a',
          offerNo: 'ABC/001/2023',
          offerDate: '01 Januari 2023',
          finalNegotiationDate: '05 Januari 2023',
          termOfPayment: 'term of payment',
          noteNegotiation: 'note negotiation',
          picVendor: 'Budi',
          collapse: true,
        },
        {
          sequence: 3,
          isLocalPartner: false,
          vendorPrincipal: '',
          vendorName: 'PT. GHI',
          status: 'Eliminasi',
          loiNo: 'n/a',
          loiDate: 'n/a',
          offerNo: 'ABC/001/2023',
          offerDate: '01 Januari 2023',
          finalNegotiationDate: '05 Januari 2023',
          termOfPayment: 'term of payment',
          noteNegotiation: 'note negotiation',
          picVendor: 'Budi',
          collapse: true,
        }
      ]
    }
  },
  computed: {
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods: {
    clickPreviewLoi() {
      console.log('click preview skt');
    },
    changeCollapse(i) {
      this.data[i].collapse = !this.data[i].collapse
    },
    clickViewLoi() {
      console.log('view skt');
    }
  },
  components: {
    InputDisabled,
    InputSolutip,
    TiptapView,
    TableLoi
  }
}
</script>