<template>
  <div>
    <ButtonGista v-if="!editRequester" type="primary" class="w-full" color="success" @click="toggle" >{{ edit ? 'Submit' : 'Create' }}</ButtonGista>
   
    <GistaModal :value="isOpen"
			width="2/3"
			scrollable
			@input="toggle"
			customClass="max-w-2xl bg-white">
          <div class="w-full p-5">
            <div class="flex justify-center p-3 mb-6">
              <h1 class="text-xl text-gray">Buat Persetujuan</h1>
            </div>
            <div class="flex justify-center p-3 mb-6">
              <p class="text-center text-gray">Pastikan seluruh informasi sudah benar</p>
            </div>
            <div class="flex justify-center">
              <ButtonGista 
                type="primary"
                color="success"
                @click="clickSubmit"
                customClass="w-full sm:w-56">
                {{ edit ? 'Submit' : 'Create' }}
              </ButtonGista>

            </div>
          </div>
    </GistaModal>
  </div>
</template>

<script>
export default {
  name: 'SubmitChangeApprovals',
  props: ['edit', 'editRequester', 'isOpen'],
  methods: {
    toggle() {
      this.$emit('clickToggle')
    },
    clickSubmit() {
      this.$emit('click')
    }
  }
}
</script>