<template>
  <div class="w-full border-b-2 border-gray-lightest">
    <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse">
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">UPLOADED SIGNED APPROVAL</h5>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.uploadSigned ? 'rotate-180' : 'rotate-0'"></p>
        </div>
      </div>
    </div>
    <div class="mb-6" v-show="collapse.uploadSigned">
      <div class="col-span-2 " v-if="file" >
        <div>
          <p class="mb-1 text-sm font-medium text-gray">Approval Document</p>
        </div>
        <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
          <div class="text-base text-gray">file.pdf</div>
          <span class="cursor-pointer icon-eye text-primary" @click="clickViewFile(i)"></span>
        </div>
      </div>
      <div v-else class="flex items-center text-warning-darkest">
        <span class="w-6 icon-info "></span>
        <div class="text-base ">No Approval Document uploaded yet</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "UploadSignedApproval",
  props: ['collapse'],
  data() {
    return {
      file: true
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods: {
    changeCollapse() {
      console.log('')
      this.$emit('changeCollapse')
    },
    clickViewFile() {
      console.log('clickViewFile')
    }
  }
}
</script>