<template>
  <div>
    <div class="border-b" :class="`${error? 'border-error': 'border-gray-light'}`">
      <input 
        @input="changeInput"
        @keypress="changeKeypress"
        @blur="changeBlur"
        @focus="changeFocus"
        type="text" 
        :placeholder="placeholder"
        :title="error"
        :value="value"
        :class="`${error?'text-error':'text-gray-darkest'} ${bgColor ?? ''} ${bgTransp ? 'bg-transparent' : ''} w-full text-sm border-none focus:ring-0 text-left p-0 ${textAlign}`"
        >
      </div>
      <div
        v-if="this.$slots['message'] != undefined"
        class="text-xs mt-1"
        :class="[{ 'text-error': error }]"
      >
        <slot name="message"></slot>
      </div>
  </div>
</template>

<script>
export default {
  name: "InputBorderB",
  props: ['value', 'error', 'placeholder', 'bgColor', 'textAlign', 'bgTransp'],
  methods: {
    changeInput(e) {
      this.$emit('input', e.target.value)
      this.$emit('handleInput', e)
    },
    changeKeypress(e) {
      this.$emit('keypress', e)
    },
    changeBlur() {
      this.$emit('blur')
    },
    changeFocus() {
      this.$emit('focus')
    }
  }
}
</script>

<style>
  .bg-transparent {
    background-color: transparent;
  }
</style>