<template>
  <div>
    <label
      class="block text-sm text-gray md:text-left pr-4 truncate"
      :class="{ 'text-error': error, 'mb-1': label }"
    >
      {{ label }} <span v-if="mandatory" class="text-error">*</span>
    </label>
    <div class="relative text-base w-full">
      <div
        class="flex items-center border rounded-md"
        :class="{
          'border border-gray-lightest bg-gray-lightest text-gray-light cursor-not-allowed':
            disabled,
          'bg-white border hover:shadow-primary-sm': !disabled,
          'border-primary': !isFocus,
          'hover:shadow-secondary-sm shadow-secondary-sm border-secondary':
            isFocus,
          'border-error text-error': error,
        }"
      >
        <div
          class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
          :class="{'h-14': size === 'large', 'h-12': size === 'medium'}"
          v-if="iconLeft"
        >
          <span :class="iconLeft"></span>
        </div>
        <input
          type="text"
          class="w-full border-none focus:ring-0 rounded-md placeholder-gray-light text-gray"
          :class="{
            'pl-8': iconLeft,
            ' bg-gray-lightest text-gray-darkest cursor-not-allowed': disabled,
          }"
          @input="onChange"
          :placeholder="placeholder"
          @focus="focusInput"
          @blur="blurInput"
          @keydown.enter="onEnter"
          :disabled="disabled"
          v-model="computedValue"
          />
        <div
          @click="changeIsOpen"
          class="text-base pr-2 items-center flex inset-y-0 right-0 text-center"
          :class="{'h-14': size === 'large', 'h-12': size === 'medium'}"
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
            :class="isOpen ? 'rotate-180' : 'rotate-0'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
      <div
        v-show="isOpen"
        class="absolute left-0 right-0 mb-4 rounded-md bg-white shadow-primary-sm z-50 overflow-auto max-h-60"
        ref="scrollSearch"
        v-infinite-scroll="loadMore" :infinite-scroll-disabled="busy" :infinite-scroll-distance="limit"
      >
        <ul>
          <li
            @click="isOpen = false"
            class="px-3 py-2 transition-colors duration-100 text-sm text-gray hover:bg-secondary hover:text-white"
            v-if="options.length < 1 && !isLoading"
          >
            <slot name="emptyResult"> Tidak Ada Data </slot>
          </li>
          
          <li
            v-else
            v-for="(result, i) in data"
            :key="i"
            @mousedown="setResult(result)"
            class="cursor-pointer px-3 py-2 transition-colors duration-100 text-sm text-gray hover:bg-secondary hover:text-white"
          >
            <slot name="option" :slot-props="result">
              {{ typeof result === "object" ? result[textField] : result }}
            </slot>
          </li>
          <li
            class="px-3 py-2 transition-colors duration-100 text-sm text-center"
            v-if="isLoading"
          >
            <p name="loading"> Sedang Ambil Data... </p>
          </li>
        </ul>
      </div>
      <div v-if="error" class="text-error text-xs mt-1">
        <slot name="message"></slot>
      </div>
    </div>
  </div>

</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
export default {
  name: 'AutocompleteSolutip',
  directives: {infiniteScroll},
  props: {
    size: {
      type: String,
      default: 'large',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    textField: {
      type: String,
      required: false,
      default: "text",
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    disabled: Boolean,
    error: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    mandatory: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 500,
    }
  },
  data() {
    return {
      isOpen: false,
      isFocus: false,
      result: null,
      data: [],
      busy: false,
      limit: 10,
      timeout: null,
      timeoutLoadMore: null,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    options() {
      this.loadMore()
    },
  },
  methods: {
    changeIsOpen() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen
        this.data = []
        this.$emit('focus')
      }
    },
    setResult(result) {
      this.result = result;
      this.$emit("updateAutoComplete", this.result)
      this.isOpen = false;
      this.isFocus = false;
    },
    onChange(e) {
      if (this.timeout) {
        clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {
        this.isOpen = true
        this.data = []
        this.$emit("updateInput", e.target.value)
      }, this.delay)
    },
    onEnter() {
      this.focusInput()
    },
    focusInput() {
      this.isOpen = true
      this.isFocus = true
      this.data = []
      this.$emit('focus')
    },
    blurInput() {
      this.$emit('blur')
      this.isFocus = false
      this.isOpen = false
    },
    loadMore() {
      this.busy = true
      if (this.timeoutLoadMore) {
        clearTimeout(this.timeoutLoadMore)
      }
      this.timeoutLoadMore = setTimeout(() => {
        const append = this.options.slice(0, this.data.length + this.limit)
        this.data = append
        this.busy = false
      }, this.delay)
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
}

</script>