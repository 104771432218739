<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex justify-between my-6">
        <div>
          <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
        </div>
        <div>
            <h4 class="text-lg font-medium text-gray-darkest">Kirim Revisi Penawaran Harga - {{ reviseOffer.negotiationStatus }}</h4>
        </div>
        <div></div>
      </div>
      <ValidationObserver ref="formReviseOffer">
        <div>
          <div class="mb-6">
            <div class="w-full mb-1">
              <label class="text-sm text-tertiary">{{negotiationDetails?.isLocalPartner? 'Local Partner' : 'Vendor'}} #{{negotiationDetails?.vendorCandidateSequence}}</label>
              <label v-if="negotiationDetails.isNewVendor" class="text-xs text-success bg-success-lightest rounded py-1 px-2 ml-3.5">New Vendor</label>
            </div>
            <h5 class="text-base text-gray-darkest">{{reviseOffer.vendorName}}</h5>
          </div>
          <div class="flex items-center mb-6">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">Revisi Penawaran Harga</h5>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 mb-3.5 pb-4 border-b border-gray-lightest"
            :class="{
              'grid-cols-1': projectDetailContainerWidth < 768,
              'grid-cols-2': projectDetailContainerWidth >= 768
            }">
            <InputDisabled label="Project ID" :value="reviseOffer.projectId"/>
            <InputDisabled label="Request No." :value="reviseOffer.requestNo"/>
            <InputDisabled label="Vendor Name" :value="reviseOffer.vendorName"/>
            <InputDisabled label="Project Name" :value="reviseOffer.projectName"/>
            <InputDisabled label="Request By" :value="reviseOffer.requestByName?.toLowerCase()" class="capitalize"/>
            <InputDisabled label="Created By" :value="reviseOffer.createdBy?.toLowerCase()" class="capitalize"/>
            <InputDisabled v-if="workflowGsit || workflowGsitUkkp" label="PIC GSIT" :value="reviseOffer.picGsitName?.toLowerCase()" class="capitalize"/>
            <InputDisabled v-if="workflowUkkp || workflowGsitUkkp" label="PIC UKKP" :value="reviseOffer.picUkkpName?.toLowerCase()" class="capitalize"/>
            <InputDisabled label="Offer No" :value="reviseOffer.offerNo"/>
            <InputDisabled label="Offer Date" :value="reviseOffer.offerDate"/>
            <InputDisabled label="Date Created" :value="reviseOffer.createdDate"/>
          </div>
          <div class="mb-6">
            <div class="mb-1">
              <p class="text-sm font-medium" :class="error['Project Type']? 'text-error' : 'text-gray'">Project Type <span class="text-error">*</span></p>
            </div>
            <div class="flex flex-wrap">
              <gista-checkbox @change="changeProjectType($event, 'Hardware')" :modelValue="reviseOffer.projectType?.includes('Hardware')" class="mr-4 text-sm font-medium text-gray">Hardware</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'Software')" :modelValue="reviseOffer.projectType?.includes('Software')"  class="mr-4 text-sm font-medium text-gray">Software</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'Service')" :modelValue="reviseOffer.projectType?.includes('Service')"  class="mr-4 text-sm font-medium text-gray">Service</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'Maintenance')" :modelValue="reviseOffer.projectType?.includes('Maintenance')"  class="mr-4 text-sm font-medium text-gray">Maintenance</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'Link-Communication')" :modelValue="reviseOffer.projectType?.includes('Link-Communication')"  class="text-sm font-medium text-gray">Link-Communication</gista-checkbox>
            </div>
            <div v-if="error['Project Type']">
              <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
            </div>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 mb-3.5"
            :class="{
              'grid-cols-1': projectDetailContainerWidth < 768,
              'grid-cols-2': projectDetailContainerWidth >= 768
            }">
            <div>
              <div class="flex">
                <label class="block mb-1 text-sm font-medium text-gray" :class="error['Meeting']? 'text-error' : 'text-gray'">Meeting<span class="text-error">*</span></label>
              </div>
              <GroupRadioButton class="h-11"
                :options="['Online', 'Offline']"
                mandatory
                name="meeting"
                :selected="reviseOffer.meeting"
                @change="changeMeeting($event)" />
              <div v-if="error['Meeting']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div>
              <ValidationProvider name="Meeting Date"
                rules="required"
                v-slot="{ errors }">
                <div class="flex">
                  <label class="block mb-1 text-sm font-medium text-gray" :class="errors?.[0]? 'text-error' : 'text-gray'">Date<span class="text-error">*</span></label>
                </div>
                <Datepicker2
                  mode="date"
                  placeholder="dd/mm/yyyy"
                  size="large"
                  v-model="reviseOffer.meetingDate"
                  :max-date="new Date()"
                  data-vv-as="Masa selesai"
                  :error="!!errors[0]"
                >
                  <template>
                    <p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
                  </template>
                </Datepicker2>
              </ValidationProvider>
            </div>
            <div>
              <SelectSolutip label="Time (Start)"
                size="large"
                placeholder="HH:MM"
                :options="timeStart"
                mandatory
                @change="changeTimeStart"
                :error="error['Time Start']">
                <template v-if="reviseOffer.timeStart" #selected>
                  <p class="text-gray">{{reviseOffer.timeStart}}</p>
                </template>
              </SelectSolutip>
              <div v-if="error['Time Start']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div>
              <SelectSolutip label="Time (End)"
                size="large"
                placeholder="HH:MM"
                :options="timeEnd"
                mandatory
                @change="changeTimeEnd"
                :error="error['Time End']">
                <template v-if="reviseOffer.timeEnd" #selected>
                  <p class="text-gray">{{reviseOffer.timeEnd}}</p>
                </template>
              </SelectSolutip>
              <div v-if="error['Time End']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <ValidationProvider v-if="!meetingOnline"
              name="Location"
              :rules="`${!meetingOnline ? 'required' : ''}`"
              v-slot="{ errors }">
              <InputGista 
                type="text"
                size="large"
                label="Location"
                :disabled="meetingOnline"
                v-model="reviseOffer.location"
                @input="(e) => handleCheckLength(e, 'Location')"
                :mandatory="!meetingOnline"
                :error="!!errors?.[0] || !!errorMaxLength['Location']">
                <template #message>
                  <p>{{errors?.[0] || errorMaxLength['Location']}}</p>
                </template>
              </InputGista>
            </ValidationProvider>
            <ValidationProvider 
              v-if="meetingOnline"
              name="Via Online"
              :rules="`${meetingOnline ? 'required' : ''}`"
              v-slot="{ errors }">
              <InputGista 
                type="text"
                size="large"
                label="Via Online"
                :disabled="!meetingOnline"
                v-model="reviseOffer.viaOnline"
                @input="(e) => handleCheckLength(e, 'Via Online')"
                :mandatory="meetingOnline"
                :error="!!errors?.[0] || !!errorMaxLength['Via Online']">
                <template #message>
                  <p>{{ errors[0] || errorMaxLength['Via Online'] }}</p>
                </template>
              </InputGista>
            </ValidationProvider>
            <div>
              <AutocompleteNewSolutip
                label="Negotiator 1"
                placeholder="(Search)"
                :options="negotiator"
                @updateInput="inputSearchNegotiator($event, 'searchNegotiator1')"
                @updateAutoComplete="changeNegotiator1($event)"
                @blur="blurNegotiator1"
                @focus="focusNegotiator('searchNegotiator1')"
                v-model="searchNegotiator1"
                textField="fullName"
                textField2="jobTitle"
                iconLeft="icon-search text-xl text-gray-light"
                mandatory
                :isLoading="isLoadingNegotiator"
                :error="error['Negotiator 1']"
              >
              </AutocompleteNewSolutip>
              <div v-if="error['Negotiator 1']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div>
              <AutocompleteNewSolutip
                label="Negotiator 2"
                placeholder="(Search)"
                :options="negotiator"
                @updateInput="inputSearchNegotiator($event, 'searchNegotiator2')"
                @updateAutoComplete="changeNegotiator2($event)"
                @blur="blurNegotiator2"
                @focus="focusNegotiator('searchNegotiator2')"
                v-model="searchNegotiator2"
                textField="fullName"
                textField2="jobTitle"
                iconLeft="icon-search text-xl text-gray-light"
                :isLoading="isLoadingNegotiator"
              >
              </AutocompleteNewSolutip>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-y-3.5 mb-6">  
            <div class="col-span-2">
              <AutocompleteNewSolutip
                label="Negotiation Participant"
                placeholder="(Search)"
                :options="negotiator"
                @updateInput="inputSearchNegotiatorParticipant($event)"
                @updateAutoComplete="changeNegotiationParticipant($event)"
                @blur="blurNegotiationParticipant"
                @focus="focusNegotiationParticipant"
                v-model="searchNegotationParticipant"
                textField="fullName"
                textField2="jobTitle"
                iconLeft="icon-search text-xl text-gray-light"
                mandatory
                :isLoading="isLoadingNegotiator"
                :error="error['Negotiator Participant']"
              >
              </AutocompleteNewSolutip>
              <div v-if="error['Negotiator Participant']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div class="flex flex-wrap col-span-2">
              <div v-for="(n,i) in reviseOffer.negotiationParticipant" :key="i" class="border border-gray-lightest rounded-lg p-2 mr-3.5 mb-3.5 flex items-center">
                <div class="mr-4 text-sm font-medium capitalize text-gray">{{ n.fullName?.toLowerCase() }}</div>
                <span class="cursor-pointer icon-x-circle text-error" @click="clickRemoveNegotationParticipant(i)"></span>
              </div> 
            </div>
            <div class="w-full col-span-2 border border-dashed border-gray-lightest"></div>
            <div class="col-span-2">
              <ValidationProvider name="Term of Payment"
                rules="required"
                v-slot="{ errors }">
                <div class="flex items-center">
                  <label class="block mb-1 text-sm text-gray" :class="(errors?.[0] || errorMaxLength['Term of Payment'])? 'text-error' : 'text-gray'">
                    Term of Payment <span class="text-error">*</span>
                  </label>
                </div>
                <div class="">

                  <Tiptap @char="(e) => characterCount(e, 'Term of Payment')" v-model="reviseOffer.termOfPayment" :error="!!errors[0] || !!errorMaxLength['Term of Payment']">
                    <template #message>
                      <p>{{ errors[0] || errorMaxLength['Term of Payment'] }}</p>
                    </template>
                  </Tiptap>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-span-2">
              <ValidationProvider name="Negotiation Note"
                rules="required"
                v-slot="{ errors }">
                <div class="flex items-center">
                  <label class="block mb-1 text-sm text-gray" :class="(errors?.[0] || errorMaxLength['Negotiation Note/ BCA Request'])? 'text-error' : 'text-gray'">
                    Negotiation Note/ BCA Request <span class="text-error">*</span>
                  </label>
                </div>
                <div class="">
                  <Tiptap @char="(e) => characterCount(e, 'Negotiation Note/ BCA Request')" v-model="reviseOffer.negotiationNote" :error="!!errors[0] || !!errorMaxLength['Negotiation Note/ BCA Request']">
                    <template #message>
                      <p>{{ errors[0] || errorMaxLength['Negotiation Note/ BCA Request'] }}</p>
                    </template>
                  </Tiptap>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-span-2">
              <div class="flex items-center">
                <label class="block mb-1 text-sm text-gray" :class="errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
                  Note Internal BCA
                </label>
              </div>
              <div class="">
                <Tiptap @char="(e) => characterCount(e, 'Note Internal BCA')" v-model="reviseOffer.internalNote" :error="!!errorMaxLength['Note Internal BCA']">
                  <template #message>
                    {{ errorMaxLength['Note Internal BCA'] }}
                  </template>
                </Tiptap>
              </div>
            </div>
            <div class="col-span-2">
              <div class="">
                <ValidationProvider
                  ref="proofOfNegotationMeeting"
                  >
                  <p class="mb-1 text-sm text-gray" :class="(errorFileType.proofOfMeeting || error['Proof Of Meeting'])? 'text-error' : 'text-gray'">Proof of Negotiation Meeting <span class="text-sm text-error">*</span></p>
                  <div class="flex items-center justify-between w-full border rounded-lg h-14"
                    :class="`${errorFileType.proofOfMeeting || error['Proof Of Meeting'] ? 'border-error' : 'border-primary'}`">
                    <input type="file"
                      id="upload-file-proofOfNegotiationMeeting"
                      ref="fileProof"
                      hidden
                      @change="chooseFile($event, 'proofOfNegotiationMeeting')" />
                    <div class="flex justify-between" style="width: calc(100% - 7rem);">
                      <span class="px-4 text-sm text-gray" :class="projectDetailContainerWidth < 640 ? '' : 'truncate'">{{ reviseOffer?.proofOfMeetingFileName }}</span>
                      <span v-if="reviseOffer?.proofOfMeetingFileName" class="flex items-center pr-4 text-xl cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="clickRemoveFileProofOfMeeting"></span>
                    </div>
                    <label v-if="!loadingProofOfMeetingUpload" for="upload-file-proofOfNegotiationMeeting"
                      class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
                      Pilih File
                    </label>
                    <label v-else
                      class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
                      <div class="w-6 h-6 ease-linear border-4 border-t-4 rounded-full loader border-primary"></div>
                    </label>
                  </div>
                  <div>
                    <p class="mt-1 text-xs text-tertiary"
                      :class="{ 'text-error': errorFileType.proofOfMeeting || error['Proof Of Meeting'] }">
                      Supported file: .pdf .jpg .jpeg .png, max file size: 10 MB
                    </p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-span-2">
              <div class="mb-1">
                <p class="mb-1 text-sm text-gray">File Quotation</p>
              </div>
              <div class="flex items-center justify-between w-full border rounded-lg border-primary h-14">
                <div class="px-4 py-2">
                  <p class="text-base font-medium text-gray">{{ findFileQuotation?.fileName }}</p>
                </div>
                <div class="flex items-center px-4">
                  <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(findFileQuotation?.fileName, findFileQuotation?.documentNo)"></p>
                </div>
              </div>
            </div>
            <div class="col-span-2" v-for="n,i in reviseOfferAttachmentsFilteredNonDeleted" :key="i">
              <div>
                <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
              </div>
              <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
                <div class="text-base text-gray">{{ n.fileName }}</div>
                <span class="cursor-pointer icon-trash-2 text-error" @click="clickRemoveFileAttachment(n)"></span>
              </div>
            </div>
            <div class="col-span-2">
              <ValidationProvider
                ref="lampiranBan"
                >
                <div class="flex flex-wrap items-center">
                  <div class="flex" v-if="maxAttachment">
                    <span class="mr-2 icon-file-plus text-secondary"></span>
                    <input type="file"
                      id="upload-file-attachment"
                      ref="fileAttachment"
                      hidden
                      @change="addAttachment($event, 'lampiranBan')" />
                    <label for="upload-file-attachment"
                      class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
                      Add Attachment
                    </label>
                  </div>
                  <p class="mr-3.5" :class="{ 'text-error': errorFileType.attachment, 'text-gray': !errorFileType.attachment }">Max Attachment: 10 </p>
                  <p :class="{ 'text-error': errorFileType.attachment, 'text-gray': !errorFileType.attachment }">Supported file: .pdf, max file size: 10 MB</p>
                </div>
              </ValidationProvider>
            </div>
            <div :class="projectDetailContainerWidth < 640? 'col-span-2': 'col-span-1'" >
              <div class="mb-1">
                <p class="text-sm font-medium text-gray">Cost Type</p>
              </div>
              <div class="flex h-12">
                <radio-gista disabled :modelValue="negotiationOfferGet.costType === 'FIX_COST'? 'FIX_COST': ''" nativeValue="FIX_COST" class="pr-4 text-gray">Fixed</radio-gista>
                <radio-gista disabled :modelValue="negotiationOfferGet.costType === 'RATE_COST'? 'RATE_COST': ''" nativeValue="RATE_COST" class="pr-4 text-gray">Rate</radio-gista>
                <radio-gista disabled :modelValue="negotiationOfferGet.costType === 'MIX_COST'? 'MIX_COST': ''" nativeValue="MIX_COST" class="text-gray">Mixed</radio-gista>
              </div>
            </div>
            <div :class="projectDetailContainerWidth < 640? 'col-span-2': 'col-span-1'">
              <div class="mb-1">
                <p class="text-sm font-medium text-gray">VAT</p>
              </div>
              <div class="">
                <InputDisabled 
                  :value="taxText"
                />
              </div>
            </div>
          </div>
          <div class="mb-6">
            <div class="flex flex-wrap justify-between gap-3 mb-6">
              <div class="flex items-center">
                <span class="mr-4 icon-list"></span>
                <h5 class="text-lg font-medium text-gray-darkest">Negotiation Detail</h5>
              </div>
              <div class="flex items-center cursor-pointer" @click="clickToggleMaximizeTable">
                <span class="mr-3 text-lg icon-maximize text-gray hover:text-gray-dark"></span>
                <p class="text-sm font-semibold text-gray hover:text-gray-dark" >Maximize table</p>
              </div>
            </div>
            <MaximizeTableDetail v-if="menuRfpNegotiationOfferGet?.canRead && isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable" />
            <NegotiationTableDetail v-if="menuRfpNegotiationOfferGet?.canRead"/>
          </div>
          <div>
            <div class="flex justify-between" :class="projectDetailContainerWidth < 640 ? 'flex-col gap-6 w-full' : ''">
              <ReviseOfferSaveAsDraft v-if="menuRfpNegotiationOfferRevision?.canRead" :isOpen="isOpenSaveAsDraft" :disabled="loadingProofOfMeetingUpload" @clickToggle="toggleSaveAsDraft($event)" @click="clickSaveAsDraft" />
              <SendEmailReviseOffer v-if="menuRfpNegotiationOfferRevision?.canRead" :isOpenModalSendEmail="isOpenModalSendEmail" :optionSendEmail="reviseOffer.optionSendEmail" :isLocalPartner="this.negotiationDetails.isLocalPartner" :disabled="loadingProofOfMeetingUpload" @changeOptionSendEmail="changeOptionSendEmail" @clickToggle="toggleClickSendEmail($event)" @click="clickSendEmail"/>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <BanLoading v-else/>
  </div>
</template>

<script>
import GroupRadioButton from "@/core/components/custom/GroupRadioButton.vue";
import NegotiationTableDetail from "@/core/components/rfp/negotiation/NegotiationTableDetail.vue";
import SendEmailReviseOffer from "@/core/components/modal/negotiation/SendEmailReviseOffer.vue";
import ReviseOfferSaveAsDraft from "@/core/components/modal/negotiation/ReviseOfferSaveAsDraft.vue";
import MaximizeTableDetail from "@/core/components/rfp/negotiation/MaximizeTableDetail.vue";
import Tiptap from '@/core/components/custom/Tiptap.vue'
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import SelectSolutip from "@/core/components/custom/Select.vue";
import { MESSAGE_EMPTY_FIELD, MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'
import Datepicker2 from '@/core/components/custom/DatePicker2.vue'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import { formatDate } from '@/core/components/helpers/format.js'
import { WF_RFP_GSIT, WF_RFP_GSIT_UKKP, WF_RFP_UKKP, PROJECT_TYPE_RFP } from '@/core/constant/negotiation.js'
import BanLoading from "@/core/components/rfp/loading/BanLoading.vue";
 

export default {
  name: 'ReviseOffer',
  data() {
    return {
      time: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      timeStart: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      timeEnd: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      searchNegotiator1: '',
      searchNegotiator2: '',
      searchNegotationParticipant: '',
      isLoading: false,
      isLoadingNegotiator: false,
      attachments: [''],
      loadingProofOfMeetingUpload: false,
      isOpenModalSendEmail: false,
      isOpenSaveAsDraft: false,
      MESSAGE_EMPTY_FIELD,
      onlineText: 'Online',
      offlineText: 'Offline',
      isOpenMaximizeTable: false,
      error: {
        "Project Type": false,
        "Meeting": false,
        "Time Start": false,
        "Time End": false,
        "Negotiator 1": false,
        "NegotiatorParticipant": false,
        "Proof Of Meeting": false,
      },
      errorMaxLength: {
        'Negotiation Note': '',
        'Term of Payment': '',
        'Note Internal BCA': '',
        'Via Online': '',
        'Location': ''
      },
      errorFileType: {
        proofOfMeeting: false,
        attachment: false
      }
    }
  },
  computed: {
    reviseOffer: {
      get() {
        return this.$store.state.negotiation.reviseOffer
      },
      set(value) {
        this.$store.commit('negotiation/SET_REVISE_OFFER', value)
      }
    },
    menuRfpNegotiationOfferGet() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferGet']
    },
    negotiationOfferGet() {
      return this.$store.state.negotiation.negotiationOfferGet
    },
    menuRfpNegotiationOfferRevision() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferRevision']
		},
    findFileQuotation() {
      return this.negotiationOfferGet?.attachments?.find(e => e.fileRef === 'fileQuotation')
    },
    masterTax() {
      return this.$store.state.negotiation.masterTax.map(e => {
        if (e.ppn === 0) {
          return { text: e.taxType, taxId: e.taxId }
        }
        return { text: `${e.taxType} - ${e.ppn}%`, taxId: e.taxId }
      })
    },
    negotiator() {
      return this.$store.state.negotiation.negotiator.filter(e => !this.filterForNegotiator.includes(e.userDomain))
    },
    filterForNegotiator() {
      let people = []
      if (this.searchNegotiator1) {
        people.push(this.reviseOffer.firstNegotiatorId)
      }
      if (this.searchNegotiator2) {
        people.push(this.reviseOffer.secondNegotiatorId)
      }
      const negotiationParticipantUserDomain = this.reviseOffer.negotiationParticipant.map(e => e.userDomain)
      people = [ ...people, ...negotiationParticipantUserDomain]
      return people
    },
    negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    taxText() {
      return this.negotiationOfferGet?.tax?.ppn === 0 ? this.negotiationOfferGet?.tax?.taxType : `${this.negotiationOfferGet?.tax?.taxType} - ${this.negotiationOfferGet?.tax?.ppn}%`  
    },
    reviseOfferAttachmentsFilteredNonDeleted() {
      return this.reviseOffer.attachments.filter(e => !e.isDeleted)
    },
    maxAttachment() {
      return this.reviseOfferAttachmentsFilteredNonDeleted.length < 10
    },
    meetingOnline() {
      return this.reviseOffer.meeting === this.onlineText
    },
    workflowGsit() {
      return this.reviseOffer?.workflow === WF_RFP_GSIT
    },
    workflowGsitUkkp() {
      return this.reviseOffer?.workflow === WF_RFP_GSIT_UKKP
    },
    workflowUkkp() {
      return this.reviseOffer?.workflow === WF_RFP_UKKP
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
    selectedIdxOffer: {
      get() {
        return this.$store.state.negotiation.selectedIdxOffer
      },
      set(value) {
        this.$store.commit('negotiation/SET_SELECTED_IDX_OFFER', value)
      }
    },
  },
  methods: {
    formatDate,
    checkErrorLength(){
      return Object.values(this.errorMaxLength).every(e => !e)
    },
    messageErrorLength(){
      const filterError = Object.keys(this.errorMaxLength).filter(e => this.errorMaxLength[e])
      return filterError.join(', ')
    },
    handleCheckLength(e, name) {
      if(name === 'Via Online' && e?.length > 176) this.errorMaxLength[name] = `Kolom ${name} Maksimal 176 Karakter`
      else if(name === 'Location' && e?.length > 128) this.errorMaxLength[name] = `Kolom ${name} Maksimal 128 Karakter`
      else this.errorMaxLength[name] = ''
    },
    characterCount(e, name) {
      if(e > 4500) this.errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
      else this.errorMaxLength[name] = ''
    },
    clickBackToVendorDetail() {
      this.$store.commit('negotiation/SET_SHOW_REVISE_OFFER', false)
    },
    changeMeeting(e) {
      this.reviseOffer.meeting = e
      this.error["Meeting"] = false
      if (this.meetingOnline) {
        this.handleCheckLength('', 'Location')
        this.reviseOffer.location = ''
      } else {
        this.handleCheckLength('', 'Via Online')
        this.reviseOffer.viaOnline = ''
      }
    },
    toggleClickSendEmail(val) {
      this.isOpenModalSendEmail = val
    },
    toggleSaveAsDraft(val) {
      this.isOpenSaveAsDraft = val
    },
    async chooseFile(e, fileRef) {
      const file = e.target?.files?.[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf','image/jpeg','image/png'] })
      if (checkFileValidation) {
        console.log('error validation');
        if(!this.reviseOffer.proofOfMeetingFilePath) this.errorFileType.proofOfMeeting = true
      } else {
        try {
          this.loadingProofOfMeetingUpload = true
          this.errorFileType.proofOfMeeting = false
					const encrypted = await this.$store.dispatch('file/hashMD5',file)
          const formData = new FormData()
					formData.append('file', file)
					formData.append('fileRef', fileRef)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
          formData.append('projectType', PROJECT_TYPE_RFP)
					const response = await this.$store.dispatch('file/uploadFile', formData)
          if (response?.status < 300 && response?.status >= 200) {
            console.log(response?.headers, 'response headers upload file');
            if (this.reviseOffer.proofOfMeetingFilePath && this.reviseOffer.proofOfMeetingDocumentNo) {
              this.reviseOffer.deletedProofOfNegotiationDocumentNoList = [ ...this.reviseOffer.deletedProofOfNegotiationDocumentNoList, this.reviseOffer.proofOfMeetingDocumentNo]
            }
            const data = response.data.result
            this.reviseOffer.proofOfMeetingDocumentNo = data.documentNo
            this.reviseOffer.proofOfMeetingFileName = data.fileName
            this.reviseOffer.proofOfMeetingFilePath = data.filePath
            this.error["Proof Of Meeting"] = false
            this.loadingProofOfMeetingUpload = false
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }

        } catch (error) {
          this.loadingProofOfMeetingUpload = false
					this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
      }
      //reset input file ref
      console.log(this.$refs['fileProof']);
			if (this.$refs['fileProof']) {
				this.$refs['fileProof'].value = ''
			}
    },
    clickRemoveNegotationParticipant(i) {
      this.reviseOffer.negotiationParticipant = this.reviseOffer.negotiationParticipant.filter((_,idx) => idx !== i)
    },
    async clickRemoveFileProofOfMeeting() {
			try {
				this.modal.modalLoading = true
        if (this.reviseOffer.proofOfMeetingDocumentNo) {
          this.reviseOffer.deletedProofOfNegotiationDocumentNoList = [ ...this.reviseOffer.deletedProofOfNegotiationDocumentNoList, this.reviseOffer.proofOfMeetingDocumentNo ]
        } else {
          const response = await this.$store.dispatch('file/deleteFile', this.reviseOffer.proofOfMeetingDocumentNo || this.reviseOffer.proofOfMeetingFileName)
          if (response?.status >= 300) {
            this.$store.dispatch('modal/throwError', { response })
          }
        }
				this.modal.modalLoading = false
        this.error["Proof Of Meeting"] = false
        if (this.$refs['fileProof']) {
          this.$refs['fileProof'].value = ''
        }
        this.reviseOffer.proofOfMeetingDocumentNo = null
        this.reviseOffer.proofOfMeetingFileName = null
        this.reviseOffer.proofOfMeetingFilePath = null
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
    async clickRemoveFileAttachment(n){
      try {
				this.modal.modalLoading = true
				const fileAttachment = this.reviseOffer.attachments.find(e => e.filePath === n.filePath)
        if (fileAttachment?.attachmentId > 0 ) {
          this.reviseOffer.attachments = this.reviseOffer.attachments.map(e => (e.filePath === fileAttachment.filePath? ({ ...e, isDeleted: true }): e))
        } else {
          const response = await this.$store.dispatch('file/deleteFile', fileAttachment.documentNo || fileAttachment.fileName)
          if (response?.status < 300 && response?.status >= 200) {
            this.reviseOffer.attachments = this.reviseOffer.attachments.filter(e => e.filePath !== n.filePath)
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        }
				this.modal.modalLoading = false
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
    },
    async addAttachment(e, fileRef) {
      const file = e.target?.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
      if(checkFileValidation){
        this.errorFileType.attachment = true
      } else {
        try {
          this.modal.modalLoading = true
          this.errorFileType.attachment = false
          const encrypted = await this.$store.dispatch('file/hashMD5',file)
          const formData = new FormData()
          formData.append('file', file)
          formData.append('fileRef', fileRef)
          //checksum encrypted md5
          formData.append('checksum', encrypted)
          formData.append('projectType', PROJECT_TYPE_RFP)
          const response = await this.$store.dispatch('file/uploadFile', formData)
          if (response?.status < 300 && response?.status >= 200) {
            console.log(response?.headers, 'response headers upload file');
            const data = response.data.result
            const fileAfterUpload = {
              fileName: data.fileName,
              filePath: data.filePath,
              documentNo: data.documentNo,
              fileRef: fileRef,
              isDeleted: false,
              attachmentId: 0
            }
            this.reviseOffer.attachments = [ ...this.reviseOffer.attachments, fileAfterUpload ]
            this.modal.modalLoading = false
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        } catch (error) {
          this.modal.modalLoading = false
          this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
        
      }
      if (this.$refs['fileAttachment']) {
        this.$refs['fileAttachment'].value = ''
      }
    },
    changeProjectType(e, type) {
      if (e) {
        this.reviseOffer.projectType = [ ...this.reviseOffer.projectType, type ]
      } else {
        this.reviseOffer.projectType = this.reviseOffer.projectType.filter(item => item !== type)
      }
      this.error["Project Type"] = false
    },
    changeTimeStart(e) {
      this.reviseOffer.timeStart = e
      this.error["Time Start"] = false
      const indexTime = this.time.indexOf(e)
      this.timeEnd = this.time.slice(indexTime+1)
    },
    changeTimeEnd(e) {
      this.reviseOffer.timeEnd = e
      this.error["Time End"] = false
      const indexTime = this.time.indexOf(e)
      this.timeStart = this.time.slice(0, indexTime)
    },
    async inputSearchNegotiator(e, stateName) {
      this.isLoadingNegotiator = true
      this[stateName] = e
      await this.$store.dispatch('negotiation/getNegotiator', { name: e, isNegotiationParticipant: false })
      this.isLoadingNegotiator = false
    },
    async inputSearchNegotiatorParticipant(e) {
      this.isLoadingNegotiator = true
      this.searchNegotationParticipant = e
      await this.$store.dispatch('negotiation/getNegotiator', { name: e, isNegotiationParticipant: true })
      this.isLoadingNegotiator = false
    },
    changeNegotiator1(e) {
      if (this.reviseOffer.secondNegotiatorId !== e.userDomain && !this.reviseOffer.negotiationParticipant.map(el => el.userDomain).includes(e.userDomain)) {
        this.searchNegotiator1 = e.fullName
        this.reviseOffer.firstNegotiatorId = e.userDomain
        this.reviseOffer.firstNegotiatorName = e.fullName
      }
      this.error["Negotiator 1"] = false
    },
    blurNegotiator1() {
      if(this.searchNegotiator1 !== '') this.searchNegotiator1 = this.reviseOffer.firstNegotiatorName
      else {
        this.reviseOffer.firstNegotiatorId = ''
        this.reviseOffer.firstNegotiatorName = ''
      }
    },
    async focusNegotiator(stateName) {
      this.isLoadingNegotiator = true
      await this.$store.dispatch('negotiation/getNegotiator', { name: this[stateName], isNegotiationParticipant: false })
      this.isLoadingNegotiator = false
    },
    changeNegotiator2(e) {
      if (this.reviseOffer.firstNegotiatorId !== e.userDomain && !this.reviseOffer.negotiationParticipant.map(el => el.userDomain).includes(e.userDomain)) {
        this.searchNegotiator2 = e.fullName
        this.reviseOffer.secondNegotiatorId = e.userDomain
        this.reviseOffer.secondNegotiatorName = e.fullName
      }
    },
    blurNegotiator2() {
      if(this.searchNegotiator2 !== '') this.searchNegotiator2 = this.reviseOffer.secondNegotiatorName
      else {
        this.reviseOffer.secondNegotiatorId = ''
        this.reviseOffer.secondNegotiatorName = ''
      }
    },
    changeNegotiationParticipant(e) {
      if (!this.reviseOffer.negotiationParticipant.map(el => el.userDomain).includes(e.userDomain) && this.reviseOffer.firstNegotiatorId !== e.userDomain && this.reviseOffer.secondNegotiatorId !== e.userDomain) {
        this.reviseOffer.negotiationParticipant = [ ...this.reviseOffer.negotiationParticipant, e ]
      }
      this.error["Negotiator Participant"] = false
    },
    blurNegotiationParticipant() {
      this.searchNegotationParticipant = ''
    },
    async focusNegotiationParticipant() {
      this.isLoadingNegotiator = true
      await this.$store.dispatch('negotiation/getNegotiator', { name: this.searchNegotationParticipant, isNegotiationParticipant: true })
      this.isLoadingNegotiator = false
    },
    openFile(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    changeOptionSendEmail(e) {
      this.reviseOffer.optionSendEmail = e
    },
    checkErrorValidate() {
      if (this.reviseOffer.projectType.length === 0) {
        this.error["Project Type"] = true
      }
      if (!this.reviseOffer.meeting) {
        this.error["Meeting"] = true
      }
      if (!this.reviseOffer.timeStart) {
        this.error["Time Start"] = true
      }
      if (!this.reviseOffer.timeEnd) {
        this.error["Time End"] = true
      }
      if (!this.reviseOffer.firstNegotiatorId) {
        this.error["Negotiator 1"] = true
      }
      if (this.reviseOffer.negotiationParticipant.length === 0) {
        this.error["Negotiator Participant"] = true
      }
      if (!this.reviseOffer.proofOfMeetingFileName) {
        this.error["Proof Of Meeting"] = true
      }
      return Object.values(this.error).every(e => !e)
    },
    messageErrorValidate() {
      const errorVeeValidate = this.$refs.formReviseOffer.errors
      const filterError = Object.keys(errorVeeValidate).filter(e => errorVeeValidate[e].length > 0)
      const filterErrorCustom = Object.keys(this.error).filter(e => this.error[e] === true)
      return [ ...filterErrorCustom, ...filterError ].join(', ')
    },
    async clickSendEmail() {
      try {
        this.modal.modalLoading = true
        const successValidate = await this.$refs.formReviseOffer.validate()
        const checkErrorValidate = this.checkErrorValidate()
        const MESSAGE_ERROR_VALIDATE = this.messageErrorValidate()
        const checkErrorLength = this.checkErrorLength()
        const MESSAGE_ERROR_LENGTH = this.messageErrorLength()
        if (!successValidate || !checkErrorValidate || !checkErrorLength) {
          this.modal.modalLoading = false
          const message = MESSAGE_ERROR_VALIDATE && MESSAGE_ERROR_LENGTH ? `, ${MESSAGE_ERROR_LENGTH}` : MESSAGE_ERROR_LENGTH
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE + message }
          this.toggleClickSendEmail(false)
          return
        }
        const dataPayload = {
          negotiationId: this.reviseOffer.negotiationId,
          summaryWith: null,
          projectId: this.reviseOffer.projectId,
          requestNo: this.reviseOffer.requestNo,
          vendorName: this.reviseOffer.vendorName,
          projectName: this.reviseOffer.projectName,
          requestById: this.reviseOffer.requestById,
          picGsitId: this.reviseOffer.picGsitId,
          picUkkpId: this.reviseOffer.picUkkpId,
          projectType: this.reviseOffer.projectType,
          meeting: this.reviseOffer.meeting,
          meetingDate: this.reviseOffer.meetingDate,
          timeStart: this.reviseOffer.timeStart,
          timeEnd: this.reviseOffer.timeEnd,
          location: this.reviseOffer.location,
          viaOnline: this.reviseOffer.viaOnline,
          firstNegotiatorId: this.reviseOffer.firstNegotiatorId,
          firstNegotiatorName: this.reviseOffer.firstNegotiatorName,
          secondNegotiatorId: this.reviseOffer.secondNegotiatorId,
          secondNegotiatorName: this.reviseOffer.secondNegotiatorName,
          negotiationParticipant: this.reviseOffer.negotiationParticipant?.map(e => e.userDomain),
          termOfPayment: this.reviseOffer.termOfPayment,
          negotiationNote: this.reviseOffer.negotiationNote,
          internalNote: this.reviseOffer.internalNote,
          proofOfMeetingDocumentNo: this.reviseOffer.proofOfMeetingDocumentNo,
          proofOfMeetingFileName: this.reviseOffer.proofOfMeetingFileName,
          proofOfMeetingFilePath: this.reviseOffer.proofOfMeetingFilePath,
          deletedProofOfNegotiationDocumentNoList: this.reviseOffer.deletedProofOfNegotiationDocumentNoList,
          attachments: this.reviseOffer.attachments,
          optionSendEmail: this.negotiationDetails.isLocalPartner ? this.reviseOffer.optionSendEmail : 'NONE',
        }
        let response
        if(this.selectedIdxOffer !== '') {
          response = await this.$store.dispatch('negotiation/postNegotiationOfferRevisionSaveAsDraft', {...dataPayload, isEditData: true})
        } else response = await this.$store.dispatch('negotiation/postNegotiationOfferRevisionSubmit', dataPayload)
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
          this.toggleClickSendEmail(false)
          setTimeout(() => {
            this.$store.commit('negotiation/SET_SHOW_REVISE_OFFER', false)
          }, 200)
          this.$store.commit('projectLog/SET_RFP_LOG_ID', this.projectLogRfp?.rfpLogs?.at(-1)?.rfpLogId)
          this.$emit('refetch')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
        this.toggleClickSendEmail(false)
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    async clickSaveAsDraft() {
      try {
        this.modal.modalLoading = true
        const checkErrorLength = this.checkErrorLength()
        const MESSAGE_ERROR_VALIDATE = this.messageErrorLength()
        if (!checkErrorLength) {
          this.modal.modalLoading = false
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE }
          this.toggleSaveAsDraft(false)
          return
        }
        const dataPayload = {
          negotiationId: this.reviseOffer.negotiationId,
          summaryWith: null,
          projectId: this.reviseOffer.projectId,
          requestNo: this.reviseOffer.requestNo,
          vendorName: this.reviseOffer.vendorName,
          projectName: this.reviseOffer.projectName,
          requestById: this.reviseOffer.requestById,
          picGsitId: this.reviseOffer.picGsitId,
          picUkkpId: this.reviseOffer.picUkkpId,
          offerNo: this.reviseOffer.offerNo,
          offerDate: this.reviseOffer.offerDate,
          projectType: this.reviseOffer.projectType,
          meeting: this.reviseOffer.meeting,
          meetingDate: this.reviseOffer.meetingDate,
          timeStart: this.reviseOffer.timeStart,
          timeEnd: this.reviseOffer.timeEnd,
          location: this.reviseOffer.location,
          viaOnline: this.reviseOffer.viaOnline,
          firstNegotiatorId: this.reviseOffer.firstNegotiatorId,
          firstNegotiatorName: this.reviseOffer.firstNegotiatorName,
          secondNegotiatorId: this.reviseOffer.secondNegotiatorId,
          secondNegotiatorName: this.reviseOffer.secondNegotiatorName,
          negotiationParticipant: this.reviseOffer.negotiationParticipant?.map(e => e.userDomain),
          termOfPayment: this.reviseOffer.termOfPayment,
          negotiationNote: this.reviseOffer.negotiationNote,
          internalNote: this.reviseOffer.internalNote,
          proofOfMeetingDocumentNo: this.reviseOffer.proofOfMeetingDocumentNo,
          proofOfMeetingFileName: this.reviseOffer.proofOfMeetingFileName,
          proofOfMeetingFilePath: this.reviseOffer.proofOfMeetingFilePath,
          deletedProofOfNegotiationDocumentNoList: this.reviseOffer.deletedProofOfNegotiationDocumentNoList,
          attachments: this.reviseOffer.attachments,
          optionSendEmail: this.negotiationDetails.isLocalPartner ? this.reviseOffer.optionSendEmail : 'NONE',
        }
        const response = await this.$store.dispatch('negotiation/postNegotiationOfferRevisionSaveAsDraft', dataPayload)
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
          this.toggleSaveAsDraft(false)
          setTimeout(() => {
            this.$store.commit('negotiation/SET_SHOW_REVISE_OFFER', false)
          }, 200)
				} else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
        this.toggleSaveAsDraft(false)
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    clickToggleMaximizeTable() {
			this.isOpenMaximizeTable = !this.isOpenMaximizeTable
		},
  },
  async mounted() {
    this.isLoading = true
    const selectedIdx = this.selectedIdxOffer !== '' ? this.selectedIdxOffer : this.negotiationDetails.offers.length - 1
		const r1 = this.$store.dispatch('negotiation/getNegotiationOfferRevision', { negotiationId: this.negotiationDetails.offers[selectedIdx].negotiationId })
    const r2 = this.$store.dispatch('negotiation/getNegotiationOfferItems', { vendorCandidateId: this.negotiationDetails.vendorCandidateId})
    const r3 = this.$store.dispatch('negotiation/getNegotiationMasterTax')
		const r4 = this.$store.dispatch('negotiation/getNegotiationOffer', { negotiationId: this.negotiationDetails.offers[selectedIdx].negotiationId })
    const [res1, res2, res3, res4] = await Promise.all([r1, r2, r3, r4])
    if ([res1?.status, res2?.status, res3?.status, res4?.status].every(e => e === 200)) {
      this.isLoading = false
      this.searchNegotiator1 = this.reviseOffer?.firstNegotiatorName
      this.searchNegotiator2 = this.reviseOffer?.secondNegotiatorName
      this.reviseOffer.meeting = this.reviseOffer.meeting || this.onlineText
    }
  },
  destroyed() {
    this.selectedIdxOffer = ''
  },
  components: {
    GroupRadioButton,
    NegotiationTableDetail,
    SendEmailReviseOffer,
    ReviseOfferSaveAsDraft,
    MaximizeTableDetail,
    Tiptap,
    AutocompleteNewSolutip,
    SelectSolutip,
    Datepicker2,
    InputDisabled,
    BanLoading
}
}
</script>