<template>
	<div class="w-full height-custom">
		<div class="w-full height-custom" v-if="negotiationShow && !isLoading">
			<div v-if="!showVendorDetail && !showReviseOffer && !showDetailBan && !showDetailOffer" class="flex justify-center mt-6">
				<ThreeButton :options="optionsTab"
							@click="changeActiveButton"
							:value="activeButton"
							:class="{'w-min min-w-custom': projectDetailContainerWidth > 640}" />
			</div>
			<NegotiationTab v-if="showNegotiationTab && !showVendorDetail && !showReviseOffer && !showDealBan && !showEditBan && !showDetailBan && !showDetailOffer"></NegotiationTab>
			<VendorDetail v-if="showNegotiationTab && showVendorDetail && !showReviseOffer && !showDealBan && !showEditBan && !showDetailBan && !showDetailOffer && menuRfpNegotiationDetails?.canRead"></VendorDetail>
			<ReviseOffer v-if="showNegotiationTab && showReviseOffer && menuRfpNegotiationOfferRevision?.canRead" @refetch="refetchProjectLog" />
			<DealBan v-if="(showDealBan || showEditBan) && showNegotiationTab && menuRfpNegotiationBanSubmit?.canCreate" @refetch="refetchProjectLog" ></DealBan>
			<BanTab v-if="!showNegotiationTab && !showDetailBan && !showDetailOffer && menuRfpNegotiationBan?.canRead" @changeActiveButton="changeActiveButton"/>
			<DetailBan v-if="showDetailBan && menuRfpNegotiationDetailsBan?.canRead"/>
			<DetailOffer v-if="showDetailOffer && menuRfpNegotiationOfferRevision?.canRead"/>
		</div>
		<div v-if="!negotiationShow && !isLoading"
			class="flex items-center justify-center height-custom">
			<div class="flex">
				<p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
				<p class="text-base font-medium text-gray">Proses Negosiasi belum di mulai</p>
			</div>
		</div>
		<NegotiationLoading v-if="isLoading"/>
	</div>
</template>

<script>
import ThreeButton from '@/core/components/custom/ThreeButton.vue';
import NegotiationTab from '@/core/components/rfp/negotiation/NegotiationTab.vue';
import BanTab from '@/core/components/rfp/negotiation/BanTab.vue'
import VendorDetail from '@/core/components/rfp/negotiation/VendorDetail.vue';
import ReviseOffer from '@/core/components/rfp/negotiation/ReviseOffer.vue';
import DealBan from '@/core/components/rfp/negotiation/Deal.vue';
import DetailBan from '@/core/components/rfp/negotiation/DetailBan.vue'
import DetailOffer from '@/core/components/rfp/negotiation/DetailOffer.vue';
import NegotiationLoading from '@/core/components/rfp/loading/NegotiationLoading.vue';
import { ITP_04_AWAITING_REVIEW, COMPLETED } from '@/core/constant/statusProject'

export default {
	name: "Negotiation",
	data() {
		return {
			activeButton: 'Negotiation',
			optionsTab: ['Negotiation', 'BAN'],
			isLoading: true,
			negotiationText: 'Negotiation'
		}
	},
	computed: {
		vendorDetail: {
      get() {
        return this.$store.state.negotiation.vendorDetail
      },
      set(value) {
        this.$store.commit('negotiation/SET_VENDOR_DETAIL', value)
      }
    },
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
		menuRfpNegotiationDetails() {
			return this.$store.getters['dashboard/menuRfpNegotiationDetails']
		},
		menuRfpNegotiationOfferRevision() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferRevision']
		},
		menuRfpNegotiationBanSubmit() {
			return this.$store.getters['dashboard/menuRfpNegotiationBanSubmit']
		},
		menuRfpNegotiationBan() {
			return this.$store.getters['dashboard/menuRfpNegotiationBan']
		},
		menuRfpNegotiationDetailsBan() {
			return this.$store.getters['dashboard/menuRfpNegotiationDetailsBan']
    },
		showVendorDetail() {
			return this.vendorDetail !== null
		},
		showReviseOffer() {
			return this.$store.state.negotiation.showReviseOffer
		},
		showNegotiationTab() {
			return this.activeButton === this.negotiationText
		},
		showDealBan() {
			return this.$store.state.negotiation.showDealBan
		},
		showEditBan() {
			return this.$store.state.negotiation.showEditBan
		},
		showDetailBan() {
			return this.$store.state.negotiation.showDetailBan
		},
		showDetailOffer() {
			return this.$store.state.negotiation.showDetailOffer
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		negotiationShow() {
			const statusProject = [ITP_04_AWAITING_REVIEW, COMPLETED]
			return this.projectLogRfp?.rfpLogs.some(e => statusProject.includes(e.statusProject))
		},
		counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
	},
	watch: {
		$route (){
			this.changeRoute()
			this.changeQueryTab()
			this.changeQueryBan()
		}
	},
	methods: {
		changeActiveButton(e) {
			this.activeButton = e
      this.$router.push({ name: 'projectNegotiation', query: { tab: e }}).catch(() => ({}))
		},
		changeRoute() {
			if (this.$route.query.vendor) {
				this.vendorDetail = this.$route.query.vendor
			} else {
				this.vendorDetail = null
			}
		},
		changeQueryTab() {
			if (this.$route.query.tab === 'BAN') {
				this.activeButton = this.optionsTab[1]
			} else {
				this.activeButton = this.optionsTab[0]
			}
		},
		changeQueryBan() {
			if (this.$route.query.negotiation && this.$route.query.detail) {
				this.$store.commit('negotiation/SET_SHOW_DETAIL_OFFER', true)
			} else if (this.$route.query.negotiation && !this.$route.query.detail) {
				this.$store.commit('negotiation/SET_SHOW_DETAIL_BAN', true)
			} else {
				this.$store.commit('negotiation/SET_SHOW_DETAIL_BAN', false)
				this.$store.commit('negotiation/SET_SHOW_DETAIL_OFFER', false)
			}
		},
		refetchProjectLog() {
			this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
		}
	},
	async mounted() {
		this.isLoading = true
		this.loading.loadingProjectLog = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
		console.log(res, res?.data.code, 'get access');
    if (res?.data.code !== 200) return
		const res1 = await this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
		if (res1?.status === 200) {
			this.isLoading = false
			this.loading.loadingProjectLog = false
		}
		this.changeRoute()
		this.changeQueryTab()
		this.changeQueryBan()
	},
	destroyed() {
		this.vendorDetail = null
		this.$store.commit('negotiation/SET_SHOW_DETAIL_OFFER', false)
		this.$store.commit('negotiation/SET_SHOW_REVISE_OFFER', false)
		this.$store.commit('negotiation/SET_SHOW_DEAL_BAN', false)
		this.$store.commit('negotiation/SET_SHOW_DETAIL_BAN', false)
		clearInterval(this.counterInterval)
	},
	components: {
    ThreeButton,
    NegotiationTab,
    BanTab,
    VendorDetail,
    ReviseOffer,
    DealBan,
    DetailBan,
    DetailOffer,
		NegotiationLoading,
	}
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
.min-w-custom {
	min-width: 20rem;
}
</style>