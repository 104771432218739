<template>
	<div class="overflow-auto">
    <div class="relative w-full overflow-auto border rounded-lg min-w-table-mobile max-h-custom border-primary border-spacing-custom">
      <div class="w-full min-width-custom">
        <table class="w-full h-px border-collapse" aria-describedby="projectProcurementApproval">
          <thead class="z-20 h-10 py-1 bg-white">
            <tr class="h-12 border-b-2 text-gray-darkest border-primary">
              <th scope="col" class="border-r border-primary min-w-64 ">
                <h5 class="flex items-center h-10 ml-2 text-sm font-semibold text-left">Selected Vendor</h5>
              </th>
              <th scope="col" class="pl-2 border-r border-primary min-w-64">
                <h5 class="flex items-center h-10 text-sm font-semibold text-left">Waiver BG</h5>
              </th>
              <th scope="col" class="pl-2 min-w-64">
                <h5 class="flex items-center h-10 text-sm font-semibold text-left">Grand Total in IDR</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="h-12 border-b-2 border-primary" v-for="item, i in selectedCandidate" :key="i" >
              <td class="pl-2 pr-3 border-r border-primary">
                <div class="py-2 min-w-60">
                  <h5 v-if="!item.vendorNamePrincipal" class="text-sm text-tertiary ">Vendor #{{ item.vendorSequence }}</h5>
                  <div v-else class="flex flex-wrap gap-x-1">
                    <h5 class="text-sm font-medium truncate text-tertiary">Local Partner #{{ item.vendorSequence }}</h5>
                    <h5 class="text-sm font-medium text-gray">of {{ item.vendorNamePrincipal }}</h5>
                  </div>
                  <h5 class="text-sm font-medium text-gray-darkest">{{ item.vendorName }}</h5>
                </div>
              </td>
              <td class="pl-2 pr-3 border-r border-primary ">
                <h5 class="text-sm text-gray-darkest">{{ item.waiverBg }}</h5>
              </td>
              <td class="pl-2 pr-3">
                <h5 class="text-sm font-bold text-gray-darkest">{{ formatCurrency(item.grandTotalInIdr) }}</h5>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="h-12">
              <td colspan="2" class="pl-2 pr-3 border-r border-primary">
                <div class="py-2 min-w-60">
                  <h5 class="text-sm font-semibold text-gray-darkest">Total Nominal Project in IDR</h5>
                </div>
              </td>
              <td class="pl-2 pr-3 py-2.5">
                <h5 class="text-2xl font-bold text-gray-darkest">{{ grandTotalinIdr }}</h5>
              </td>
            </tr>
          </tbody>
        </table>
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'

export default {
	name: 'ProjectProcurementApprovalTable',
	data() {
		return {
      data: [
        {
          vendorName: 'PT. ABC',
          vendorSequence: 1,
          isLocalPartner: false,
          vendorNamePrincipal: null,
          price: 10000000,
          totalPrice: 100,
          waiverBg: 'Waiver Performance Bond',
          vat: 'Vat Excluded - 11%',
          vatAmount: 11,
          currency: 'USD',
          exchangeRate: 10000,
          subTotal: 111,
          grandTotalInIdr: 1110000,
          totalNominalProjectInIdr: 1443000,
        },
        {
          vendorName: 'PT. Lorem A',
          vendorSequence: 1,
          isLocalPartner: true,
          vendorNamePrincipal: 'PT. Lorem Ipsum',
          price: 10000000,
          waiverBg: 'Waiver Performance Bond',
          totalPrice: 3000000,
          vat: 'Vat Included - 11%',
          vatAmount: 33000,
          currency: 'IDR',
          exchangeRate: null,
          subTotal: 333000,
          grandTotalInIdr: 333000,
          totalNominalProjectInIdr: 1443000,
        },
      ],
      vat: 11,

		}
  },
  computed: {
    selectedCandidate(){
      return this.$store.state.procurementApproval.selectedCandidate
    },
    grandTotalinIdr () {
      let total = 0
      this.selectedCandidate.map(e => {
        total += e.grandTotalInIdr
      })
      return formatCurrency(total)
    }
  },
  methods: {
    formatCurrency,
    total() {
      return this.data.reduce((a,b) => +a + +b.price, 0)
    },
    totalVat() {
      return this.total() * this.vat / 100
    },
    grandTotal() {
      return this.total() + this.totalVat()
    }
	}
}
</script>
<style scoped>
.border-spacing-custom {
  border-spacing: 0px 0px;
}
.min-width-custom {
	min-width: 40rem;
}
.min-w-60 {
	min-width: 15rem;
}
.min-w-40 {
  min-width: 10rem;
}
.min-w-table-mobile {
  min-width: 25rem;
}
</style>
