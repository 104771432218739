<template>
  <div>
    <ButtonGista type="primary" class="w-full" :color="color" @click="changeIsOpen(true)" :disabled="disabled">Continue Draft</ButtonGista>
    <ModalConfirm 
      title="Lanjutkan Draft?" 
      :message="`Draft terakhir: `" 
      icon="saved" textButton="Continue Draft" 
      :isOpen="isOpen" 
      @changeIsOpen="changeIsOpen($event)" 
      @click="clickContinueDraft"
      colorButton="success">
    </ModalConfirm>
  </div>
</template>

<script>
  export default {
    name: 'ContinueDraftApproval',
    data() {
      return {
        isOpen: false
      }
    },
    props: ['lastDate', 'disabled', 'color'],
    methods: {
      changeIsOpen(val) {
        this.isOpen = val
      },
      clickContinueDraft() {
        this.$emit('click')
        this.changeIsOpen()
      }
    },

  }
</script>