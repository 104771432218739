<template>
  <div class="w-full">
    <div class="grid gap-x-6 gap-y-2.5 mb-6"
      :class="{
        'grid-cols-1': projectDetailContainerWidth < 768,
        'grid-cols-2': projectDetailContainerWidth >= 768
      }"
    >
      <div :class="{
        'col-span-2': projectDetailContainerWidth < 768,
        'col-span-1': projectDetailContainerWidth >= 768
      }">
        <InputDisabled 
          label="Project ID" 
          value="PRJ22001"
        />
      </div>
      <div :class="{
        'col-span-2': projectDetailContainerWidth < 768,
        'col-span-1': projectDetailContainerWidth >= 768
      }">
        <InputDisabled 
          label="Request No."
          value="001/RFP/ITP-DLOG/2023"
        />
      </div>
      <div class="col-span-2">
        <InputDisabled 
          label="Project Name"
          value="Test Project Name"
        />
      </div>
      <div class="col-span-2">
        <div class="flex items-center">
          <label class="block mb-1 text-sm text-gray">
            Note Internal BCA
          </label>
        </div>
        <div class="">
          <TiptapView v-if="approval.internalNote" v-model="approval.internalNote" />
          <InputDisabled value="-" v-else/>
        </div>
      </div>
      <div class="col-span-2">
        <div class="flex items-center">
          <label class="block mb-1 text-sm text-gray">
            Note for Bank Guarantee
          </label>
        </div>
        <div class="">
          <TiptapView v-if="approval.bankGuaranteeNote" v-model="approval.bankGuaranteeNote" />
          <InputDisabled value="-" v-else/>
        </div>
      </div>
      <div class="col-span-2" v-for="n,i in approval?.attachments" :key="i">
        <div>
          <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
        </div>
        <div class="flex items-center justify-between border rounded-lg border-primary h-14">
          <div class="p-4 truncate">
            <p class="text-gray">{{ n.fileName }}</p>
          </div>
          <div class="p-4 cursor-pointer">
            <p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
          </div>
        </div>
      </div>
      <div :class="{
        'col-span-2': projectDetailContainerWidth < 768,
        'col-span-1': projectDetailContainerWidth >= 768
      }">
        <InputDisabled 
          label="Approval 1" 
          value="John Doe"
        />
      </div>
      <div :class="{
        'col-span-2': projectDetailContainerWidth < 768,
        'col-span-1': projectDetailContainerWidth >= 768
      }">
        <InputDisabled 
          label="Approval 2"
          value="John Doe"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import TiptapView from '@/core/components/custom/TiptapView.vue'

export default {
  name: "InformationDetails",
  data(){
    return {
      approval: {
        attachments: [{fileName: 'file.pdf'}],
        division: '',
        internalNote: 'internal note',
        bankGuaranteeNote: 'note for bank guarantee'
      },
      errorFileType: false,
      divisions: []
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    maxAttachment() {
      return this.approval.attachments.length < 10
    },
  },
  methods: {
    async clickRemoveFileAttachment(index){
      try {
        this.approval.attachments = this.approval.attachments.filter((e,i) => i !== index)
      } catch (error) {
        console.log(error)
      }
    },
    async addAttachment(e, fileRef) {
      const file = e.target?.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
      if(checkFileValidation){
        this.errorFileType = true
      } else {
        try {
          const fileAfterUpload = {fileName: file.name}
          this.approval.attachments = [ ...this.approval.attachments, fileAfterUpload ]
          this.errorFileType = false
        } catch (error) {
          console.log(error)
        }
        
      }
      if (this.$refs[fileRef]) {
        this.$refs[fileRef].value = ''
      }
    },
    focusRecommendation() {
      this.divisions = [
        { fullName: 'Testing' },
        { fullName: 'Testing 1' },
        { fullName: 'Testing 2' },
        { fullName: 'Testing 3' },
      ]
    },
    changeRecommendation(e) {
      this.approval.division = e.fullName
    },
    inputSearch(e) {
      console.log('search', e)
    },
  },
  components:{
    InputDisabled,
    TiptapView
  }
}
</script>