<template>
  <div>
    <div v-if="memoShow">
      <div v-if="!isCreateProcurementApproval">
        <div class="flex flex-col items-center justify-center gap-12 h-96">
          <img src="@/core/assets/icons/file/others.svg" alt="other" class="w-28" />
          <ButtonGista 
            @click="changeIsCreateProcurementApproval"
            type="primary"
            v-if="!isContinueDraft"
            customClass="">
            Create Approval
          </ButtonGista>
          <ContinueDraftApproval
            v-if="isContinueDraft"
            @click="continueDraft"
            :isContinueDraft="isContinueDraft"
          />
        </div>
      </div>
      <CreateProcurementApproval 
        v-if="isCreateProcurementApproval && !submittedProcurementApproval" 
        :isCreateProcurementApproval="isCreateProcurementApproval" 
        @changeIsCreateProcurementApproval="changeIsCreateProcurementApproval" 
        @changeIsContinueDraft="changeIsContinueDraft"
				@changeSubmittedProcurementApproval="changeSubmittedProcurementApproval"
        :isContinueDraft="isContinueDraft"
      />
			<ProcurementApprovalDetails v-if="submittedProcurementApproval"/>
    </div>
    <div v-if="!memoShow"
      class="flex items-center justify-center height-custom">
      <div class="flex">
        <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
        <p class="text-base font-medium text-gray">Proses Procurement Approval belum di mulai</p>
      </div>
    </div>
  </div>
</template>

<script>
import { UKKP_07_AWAITING_MEMO_CREATION, GSIT_07_AWAITING_MEMO_CREATION, USER_04_AWAITING_MEMO_CREATION } from '@/core/constant/statusProject'
import CreateProcurementApproval from '@/core/components/rfp/procurementApproval/CreateProcurementApproval.vue'
import ProcurementApprovalDetails from '@/core/components/rfp/procurementApproval/ProcurementApprovalDetails.vue'
import ContinueDraftApproval from '@/core/components/rfp/procurementApproval/ContinueDraftApproval.vue'

export default {
  name: "ProcurementApproval",
  data() {
    return {
      isContinueDraft: false,
    };
  },
  computed: {
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
    currentStatusProject() {
      return this.projectLogRfp?.rfpLogs[this.projectLogRfp?.rfpLogs?.length - 1]?.statusProject
    },
    memoShow() {
      const statusProject = [ UKKP_07_AWAITING_MEMO_CREATION, GSIT_07_AWAITING_MEMO_CREATION, USER_04_AWAITING_MEMO_CREATION ]
      return statusProject.includes(this.currentStatusProject) 
    },
    loading: {
      get() {
        return this.$store.state.modal.loading
      },
      set(value) {
        this.$store.commit('modal/SET_LOADING', value)
      }
    },
    isCreateProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.isCreateProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_CREATE_APPROVAL', value)
      }
    },
    submittedProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.submittedProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_SHOW_DETAIL_APPROVAL', value)
      }
    },
  },
  methods: {
    changeIsCreateProcurementApproval() {
      this.isCreateProcurementApproval = !this.isCreateProcurementApproval
    },
    changeIsContinueDraft() {
      this.isContinueDraft = !this.isContinueDraft
    },
		changeSubmittedProcurementApproval() {
			this.submittedProcurementApproval = !this.submittedProcurementApproval
		},
    continueDraft() {
      this.changeIsContinueDraft()
      this.changeIsCreateProcurementApproval()
    }
  },
  async mounted() {
    this.loading.loadingProjectLog = true
    const res = await this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
    if (res?.status === 200) {
      this.loading.loadingProjectLog = false
    }
  },
  components:{
    CreateProcurementApproval,
    ContinueDraftApproval,
		ProcurementApprovalDetails
  }
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
</style>