<template>
  <div>
    <ButtonGista size="small" type="secondary" color="gray" class="text-sm font-semibold" :class="projectDetailContainerWidth < 640 ? 'w-full' : 'w-fit'" @click="toggleIsOpen">
      <template #icon-left>
        <span class="icon-key text-2xl pr-3.5"></span>
      </template>
      View Security Code
    </ButtonGista>
    <gista-modal :value="isOpen"
			customClass="max-w-2xl bg-white"
			:scrollable="true"
			@input="toggleIsOpen">
			<div class="w-full p-6">
				<div class="flex p-3 ">
					<h1 class="text-xl font-semibold text-gray">Security Code</h1>
				</div>
				<div class="flex p-3 gap-3.5 break-all whitespace-pre-wrap ">
          <div class="flex flex-wrap text-base text-left text-gray">
            <p ref="myinput" >25f4b58325a926e930dc1eb383430287</p>
          </div>
          <div class="flex items-center">
            <div class="text-2xl cursor-pointer icon-copy" @click="copy"></div>
            <div ref="reference"></div>
          </div>
				</div>
			</div>
		</gista-modal>
  </div>
</template>

<script>
export default {
  name: 'SktLoiSecurityCode',
  data() {
    return {
      text: 'This will get copied!',
      isOpen: false
    };
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    }
  },
  methods: {
    copy() {
      const storage = document.createElement('textarea');
      storage.value = this.$refs.myinput.innerHTML;
      this.$refs.reference.appendChild(storage);
      storage.select();
      document.execCommand('copy');
      this.$refs.reference.removeChild(storage);
    },
    toggleIsOpen() {
      this.isOpen = !this.isOpen
    }
  }
};
</script>