<template>
  <div class="">
    <div class="w-full overflow-auto max-h-custom">
      <div class="w-full">
        <table class="relative w-full border-collapse" id="negotiationTable" aria-describedby="negotiationTable">
          <thead class="sticky top-0 z-20 h-10 py-1 bg-white">
            <tr class="border-b-2 text-gray-darkest border-primary">
              <th scope="col" class="min-w-12">
                <h5 class="flex items-center h-10 ml-2 text-sm font-medium text-left">No.</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 text-sm font-medium text-left">Product/Service</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 text-sm font-medium text-left">Sub Product</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 text-sm font-medium text-left">Final Price (IDR)</h5>
              </th>
            </tr>
          </thead>
          <tbody >
            <template>
              <tr v-for="item, i in items" :key="i" class="h-10">
                <td class="w-12 pl-2 pr-3">
                  <h5 class="text-sm text-gray-darkest">{{i+1}}</h5>
                </td>
                <td class="pr-3 min-w-64">
                  <h5 class="text-sm text-gray-darkest">{{ item.title }}</h5>
                </td>
                <td class="pr-3 min-w-64">
                  <h5 class="text-sm text-gray-darkest">{{ item.subProduct }}</h5>
                </td>
                <td class="pr-3 min-w-64">
                  <h5 class="text-sm text-gray-darkest">{{ formatCurrency(item.finalPrice) }}</h5>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody class="sticky bottom-0 bg-gradient-to-t from-primary-lightest to-white">
            <tr class="h-10 py-2 border-b border-primary">
              <td><span class="w-12 text-base icon-corner-down-right"></span></td>
              <td colspan="2">
                <p class="w-32 text-sm font-medium text-gray-darkest">Total Price:</p>
              </td>
              <td class="pr-3">
                <h5 class="text-sm text-success-dark">IDR {{ formatCurrency(totalFinalPrice) }}</h5>
              </td>
            </tr>
            <tr class="h-10 py-2 border-b border-primary">
              <td class="w-12"></td>
              <td colspan="2">
                <p class="w-32 text-sm font-medium text-gray-darkest">VAT (11%):</p>
              </td>
              <td class="pr-3">
                <h5 class="text-sm text-success-dark">IDR {{ formatCurrency(vatPrice) }}</h5>
              </td>
            </tr>
            <tr class="h-10 py-2 border-b border-primary">
              <td class="w-12"></td>
              <td colspan="2">
                <p class="w-32 text-sm font-medium text-gray-darkest">Total Price + VAT:</p>
              </td>
              <td class="pr-3">
                <h5 class="text-sm text-success-dark">IDR {{ formatCurrency(totalPriceAndVat) }}</h5>
              </td>
            </tr>
          </tbody>
        </table>
				
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'

export default {
  name: 'TableLoi',
  props: ['readOnly', 'usd'],
  data() {
    return {
      exchangeRate: 0,
      items: [
        { title: 'Lorem Ipsum (2 items)', subProduct: '', finalPrice: 1000000 },
        { title: 'Lorem Ipsum (2 items)', subProduct: '', finalPrice: 1000000 },
        { title: 'Lorem Ipsum (1 items)', subProduct: 'Lorem Ipsum', finalPrice: 1000000 },
      ],
    }
  },
  computed: {
    totalFinalPrice() {
      return this.items.reduce((a,b) => +a + +b.finalPrice, 0)
    },
    vatPrice() {
      return this.totalFinalPrice * 11 / 100
    },
    totalPriceAndVat() {
      return this.totalFinalPrice + this.vatPrice
    }
  },
  methods: {
    formatCurrency,
    vatIncludedChecker,
  }
}
</script>

<style scoped>
.max-h-custom {
	max-height: 24rem;
}
.min-w-12 {
	min-width: 3rem;
}
.min-w-64 {
	min-width: 16rem;
}
#negotiationTable {
	border-collapse: collapse;
}
#negotiationTable th {
	padding: 0; 
	margin: 0;
}

</style>
