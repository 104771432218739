<template>
  <div class="">
    <div class="w-full overflow-auto max-h-custom">
      <div class="w-full">
        <table class="relative w-full border-collapse" id="negotiationTable" aria-describedby="negotiationTable">
          <thead class="sticky top-0 z-20 h-10 py-1 bg-white">
            <tr class="border-b-2 text-gray-darkest border-primary">
              <th scope="col" class="min-w-12">
                <GistaCheckbox @change="changeCheckBoxAll($event)" :modelValue="allChecked" class="text-gray px-2"></GistaCheckbox>
              </th>
              <th scope="col" class="min-w-12">
                <h5 class="flex items-center h-10 text-sm font-medium text-left px-2">No.</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 text-sm font-medium text-left px-2">Product/Service</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 text-sm font-medium text-left px-2">Sub Product</h5>
              </th>
              <th scope="col" class="min-w-20">
                <h5 class="flex items-center h-10 text-sm font-medium text-left px-2">Qty</h5>
              </th>
              <th scope="col" class="min-w-20">
                <h5 class="flex items-center h-10 text-sm font-medium text-left px-2">UoM</h5>
              </th>
              <th scope="col" class="min-w-24">
                <h5 class="flex items-center h-10 text-sm font-medium text-left px-2">Qty Order</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 text-sm font-medium text-left px-2">Initial Price (IDR)</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 text-sm font-medium text-left px-2">Final Price ({{ usd ? 'USD' : 'IDR' }})</h5>
              </th>
            </tr>
          </thead>
          <tbody >
            <template>
              <tr v-for="item, i in items" :key="i" class="h-10">
                  <td>
                    <GistaCheckbox @change="changeCheckBoxItem($event, i)" :modelValue="item.checked" class="text-gray px-2"></GistaCheckbox>
                  </td>
                  <td>
                    <h5 class="text-sm text-gray-darkest px-2">{{i+1}}</h5>
                  </td>
                  <td>
                    <h5 class="text-sm text-gray-darkest px-2">{{ item.title }}</h5>
                  </td>
                  <td>
                    <h5 class="text-sm text-gray-darkest px-2">{{ item.subProduct }}</h5>
                  </td>
                  <td>
                    <h5 class="text-sm text-gray-darkest px-2">{{ item.qty }}</h5>
                  </td>
                  <td>
                    <h5 class="text-sm text-gray-darkest px-2">{{ item.uom }}</h5>
                  </td>
                  <td class="min-w-24">
                    <ValidationProvider name="Qty"
                      :rules="'decimalTwoDigits'"
                      :vid="`QtyOrder${i}`"
                      v-slot="{ errors }">
                      <InputExtraSmall
                        v-model="item.qtyOrder"
                        @input="inputQtyOrder($event, i)"
                        @blur="blurQtyOrder(i)"
                        :error="!!errors[0] || !!item.errorQtyOrder"
                        class="px-2 truncate"
                        :widthFull="true">
                        <template #message>
                          {{ errors[0] || item.errorQtyOrder }}
                        </template>
                      </InputExtraSmall>
                    </ValidationProvider>
                  </td>
                  <td>
                    <h5 class="text-sm text-gray-darkest px-2">{{ item.initPrice }}</h5>
                  </td>
                  <td>
                    <h5 class="text-sm text-gray-darkest px-2">{{ item.finalPrice }}</h5>
                  </td>
                </tr>
            </template>
          </tbody>
          <tbody class="sticky bottom-0 bg-gradient-to-t from-primary-lightest to-white">
            <tr class="h-10 py-2">
              <td></td>
              <td class="px-2"><span class="text-base icon-corner-down-right"></span></td>
              <td colspan="5" class="border-b border-primary">
                <div class="flex">
                  <div class="flex">
                    <p class="w-40 flex-auto text-sm font-medium text-gray-darkest px-2">Total</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                </div>
              </td>
              <td colspan="1" class="border-b border-primary">
                <p class="px-2 text-sm font-medium text-primary-darkest">{{ totalInitialPrice }}</p>
              </td>
              <td colspan="1" class="border-b border-primary">
                <p class="px-2 text-sm font-medium text-primary-darkest">{{ totalFinalPrice }}</p>
              </td>
            </tr>
            <tr class="h-10 py-2">
              <td colspan="2"></td>
              <td colspan="7" class="border-b border-primary">
                <div class="flex">
                  <div class="flex">
                    <p class="w-40 flex-auto text-sm font-medium text-gray-darkest px-2">Total Price</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">0</p>
                </div>
              </td>
            </tr>
            <tr class="h-10 py-2">
              <td colspan="2"></td>
              <td colspan="7" class="border-b border-primary">
                <div class="flex">
                  <div class="flex">
                    <p class="w-40 flex-auto text-sm font-medium text-gray-darkest px-2">VAT (%)</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">0</p>
                </div>
              </td>
            </tr>
            <tr class="h-10 py-2">
              <td colspan="2"></td>
              <td colspan="7" class="border-b border-primary">
                <div class="flex">
                  <div class="flex">
                    <p class="w-40 flex-auto text-sm font-medium text-gray-darkest px-2">VAT Amount</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">0</p>
                </div>
              </td>
            </tr>
            <tr class="h-10 py-2">
              <td colspan="2"></td>
              <td colspan="7" class="border-b border-primary">
                <div class="flex">
                  <div class="flex">
                    <p class="w-40 flex-auto text-sm font-medium text-gray-darkest px-2">Currency</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">USD</p>
                </div>
              </td>
            </tr>
            <tr class="h-10 py-2" v-if="!usd">
              <td colspan="2" class="border-b-2 border-primary"></td>
              <td colspan="7" class="border-b-2 border-primary">
                <div class="flex">
                  <div class="flex">
                    <p class="w-40 flex-auto text-sm font-medium text-gray-darkest px-2">Grand Total</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">0</p>
                </div>
              </td>
            </tr>
            <tr class="h-10 py-2" v-if="usd">
              <td colspan="2"></td>
              <td colspan="7" class="border-b border-primary">
                <ValidationProvider name="Exchange Rate"
                  rules="required"
                  v-slot="{ errors }">
                  <div class="flex ">
                    <div class="flex items-center">
                      <h5 class="w-40 flex-auto text-sm font-medium text-gray-darkest px-2">Exchange Rate <span v-if="!readOnly" class="text-sm font-semibold text-error">*</span></h5>
                      <p class="flex-1 text-sm font-medium text-primary-darkest">:</p>
                    </div>
                    <div v-if="!readOnly" class="pl-4">
                      <InputBorderB 
                        v-model="exchangeRate" 
                        @keypress="validationInputExchangeRate" 
                        @blur="blurExchangeRate" 
                        @input="inputExchangeRate" 
                        @handleInput="handleInputExchangeRate" 
                        :placeholder="'0'"
                        :bgTransp="true" 
                        textAlign="left"
                        :error="!!errors[0] || !!errorMaxLength['Exchange Rate']">
                        <template #message>
                          {{ errors[0] || errorMaxLength['Exchange Rate'] }}
                        </template>
                      </InputBorderB>
                    </div>
                    <div v-else>
                      <p class="pl-4 text-sm font-medium text-primary-darkest">0</p>
                    </div>
                  </div>
                </ValidationProvider>
              </td>
            </tr>
            <tr class="h-10 py-2" v-if="usd">
              <td colspan="2"></td>
              <td colspan="7" class="border-b border-primary">
                <div class="flex ">
                  <div class="flex">
                    <h5 class="w-40 flex-auto text-sm text-gray-darkest px-2">Subtotal</h5>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <div>
                    <p class="pl-4 text-sm font-medium text-primary-darkest">0</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="h-10 py-2 border-b-2 border-primary" v-if="usd">
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex ">
                  <div class="flex">
                    <h5 class="w-40 flex-auto text-sm text-gray-darkest px-2">Grand Total in IDR:</h5>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">0</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
				
			</div>
		</div>
	</div>
</template>

<script>
import InputBorderB from '@/core/components/rfp/procurementApproval/InputBorderB.vue'
import InputExtraSmall from "@/core/components/custom/InputExtraSmall"
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'

export default {
  name: 'ResultTable',
  props: ['readOnly', 'usd'],
  data() {
    return {
      exchangeRate: '',
      negoResults: {

      },
      allChecked: true,
    }
  },
  computed: {
    totalInitialPrice() {
      return this.items.reduce((a,b) => a + b.initPrice, 0)
    },
    totalFinalPrice() {
      return this.items.reduce((a,b) => a + b.finalPrice, 0)
    },
    items: {
      get() {
        return this.$store.state.procurementApproval.items
      },
      set(val) {
        this.$store.commit('procurementApproval/SET_ITEMS', val)
      }
    },
    errorMaxLength: {
      get() {
        return this.$store.state.procurementApproval.errorMaxLength
      },
      set(val) {
        this.$store.commit('procurementApproval/SET_ERROR_MAX_LENGTH', val)
      }
    },
  },
  methods: {
    formatCurrency,
    vatIncludedChecker,
    initTotalCurrency() {
      return this.negoResults?.items?.[0]?.initialCurrency
    },
    finalTotalCurrency() {
      return this.negoResults?.items?.[0]?.finalCurrency
    },
    inputQtyOrder(e, i) {
      const regexMatchDecimalTwoDigits = /^\d+\.?\d{0,2}/
      this.items[i].qtyOrder = this.items[i].qtyOrder.match(regexMatchDecimalTwoDigits)?.[0] || ''
      this.handleQtyOrder(i)
    },
    blurQtyOrder(i) {
      if (this.items[i].qtyOrder < 1) {
        this.items[i].checked = false
        this.items[i].qtyOrder = '0'
      }
    },
    handleQtyOrder(i) {
      if (this.items[i].qtyOrder > this.items[i].qty) {
        this.items[i].errorQtyOrder = 'lebih dari Qty'
      } else {
        this.items[i].errorQtyOrder = ''
      }
    },
    changeCheckBoxAll(e) {
      console.log(e, 'all');
      this.items = this.items.map(el => ({ ...el, checked: e }))
      this.allChecked = e
    },
    changeCheckBoxItem(e, i) {
      this.items[i].checked = e
      if (!e) {
        this.allChecked = false
        this.items[i].qtyOrder = '0'
      }
      console.log(e, { item: this.items[i].checked }, 'item');
    },
    handleCheckLength(e, name) {
      if(name === 'Exchange Rate' && e?.length > 13) this.errorMaxLength[name] = `Maksimal 10 digit`
      else this.errorMaxLength[name] = ''
    },
    validationInputExchangeRate(e) {
      let keyCode = e.keyCode
			if ((keyCode > 47 && keyCode < 58)) {
				return
			}
			e.preventDefault();
    },
    inputExchangeRate(e) {
      this.exchangeRate = formatThousandSeparate(e.split(',').join(''), 'usd')
      this.handleCheckLength(e, 'Exchange Rate')
    },
    handleInputExchangeRate(e) {
      const el = e.target;
			const formatted = formatThousandSeparate(el.value.split(',').join(''), 'usd')
			const count = formatted?.split(',').length
			const count2 = el.value?.split(',').length
			let sel = count > count2 ? el.selectionStart + 1 : count < count2 ? el.selectionStart - 1 : el.selectionStart
      this.$nextTick(() => {
        el.setSelectionRange(sel, sel);
      });
		},
    blurExchangeRate() {
      // const regex = /(-?\d+(\.\d+)?)/g;
      // const matches = this.exchangeRate.match(regex);
      // if (matches) {
      //   const extractedNumber = +matches.join('') || 0;
      //   const formattedNumber = extractedNumber.toLocaleString('en-US', {
      //     minimumFractionDigits: 2,
      //     maximumFractionDigits: 2,
      //   });
      //   this.exchangeRate = formattedNumber
      // } else {
      //   this.exchangeRate = "0"
      // }
    }
  },
  components: {
    InputBorderB,
    InputExtraSmall,
  }
}
</script>

<style scoped>
.max-h-custom {
	max-height: 40rem;
}
.min-w-12 {
	min-width: 3rem;
}
.min-w-20 {
  min-width: 5rem;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-64 {
	min-width: 16rem;
}
#negotiationTable {
	border-collapse: collapse;
}
#negotiationTable th {
	padding: 0; 
	margin: 0;
}

</style>
