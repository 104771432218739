var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"grid gap-x-6 gap-y-2.5 mb-6",class:{
      'grid-cols-1': _vm.projectDetailContainerWidth < 768,
      'grid-cols-2': _vm.projectDetailContainerWidth >= 768
    }},[_c('div',{class:{
      'col-span-2': _vm.projectDetailContainerWidth < 768,
      'col-span-1': _vm.projectDetailContainerWidth >= 768
    }},[_c('InputDisabled',{attrs:{"label":"Project ID","value":"PRJ22001"}})],1),_c('div',{class:{
      'col-span-2': _vm.projectDetailContainerWidth < 768,
      'col-span-1': _vm.projectDetailContainerWidth >= 768
    }},[_c('InputDisabled',{attrs:{"label":"Request No.","value":"001/RFP/ITP-DLOG/2023"}})],1),_c('div',{staticClass:"col-span-2"},[_c('InputDisabled',{attrs:{"label":"Project Name","value":"Test Project Name"}})],1),_c('div',{staticClass:"col-span-2"},[_vm._m(0),_c('div',{},[(_vm.approval.internalNote)?_c('TiptapView',{model:{value:(_vm.approval.internalNote),callback:function ($$v) {_vm.$set(_vm.approval, "internalNote", $$v)},expression:"approval.internalNote"}}):_c('InputDisabled',{attrs:{"value":"-"}})],1)]),_c('div',{staticClass:"col-span-2"},[_vm._m(1),_c('div',{},[(_vm.approval.bankGuaranteeNote)?_c('TiptapView',{model:{value:(_vm.approval.bankGuaranteeNote),callback:function ($$v) {_vm.$set(_vm.approval, "bankGuaranteeNote", $$v)},expression:"approval.bankGuaranteeNote"}}):_c('InputDisabled',{attrs:{"value":"-"}})],1)]),_vm._l((_vm.approval?.attachments),function(n,i){return _c('div',{key:i,staticClass:"col-span-2"},[_c('div',[_c('p',{staticClass:"mb-1 text-sm font-medium text-gray"},[_vm._v("Attachment #"+_vm._s(i+1))])]),_c('div',{staticClass:"flex items-center justify-between border rounded-lg border-primary h-14"},[_c('div',{staticClass:"p-4 truncate"},[_c('p',{staticClass:"text-gray"},[_vm._v(_vm._s(n.fileName))])]),_vm._m(2,true)])])}),_c('div',{class:{
      'col-span-2': _vm.projectDetailContainerWidth < 768,
      'col-span-1': _vm.projectDetailContainerWidth >= 768
    }},[_c('InputDisabled',{attrs:{"label":"Approval 1","value":"John Doe"}})],1),_c('div',{class:{
      'col-span-2': _vm.projectDetailContainerWidth < 768,
      'col-span-1': _vm.projectDetailContainerWidth >= 768
    }},[_c('InputDisabled',{attrs:{"label":"Approval 2","value":"John Doe"}})],1)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block mb-1 text-sm text-gray"},[_vm._v(" Note Internal BCA ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block mb-1 text-sm text-gray"},[_vm._v(" Note for Bank Guarantee ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 cursor-pointer"},[_c('p',{staticClass:"text-2xl icon-eye text-primary hover:text-primary-dark"})])
}]

export { render, staticRenderFns }