<template>
  <div>
    <div v-if="sktLoiShow">
      <div v-if="!isCreateSktLoi">
        <div class="flex flex-col items-center justify-center gap-12 h-96">
          <img src="@/core/assets/icons/file/others.svg" alt="other" class="w-28" />
          <ButtonGista 
            @click="changeIsCreateProcurementApproval"
            type="primary"
            v-if="!isContinueDraft"
            customClass="">
            {{ isSingleVendor ? 'Create LOI' : 'Create SKT & LOI' }}
          </ButtonGista>
          <ContinueDraftApproval
            v-if="isContinueDraft"
            @click="continueDraft"
            :isContinueDraft="isContinueDraft"
          />
        </div>
      </div>
      <CreateSktLoi 
        v-if="isCreateSktLoi && !submittedSktLoi" 
        :isCreateSktLoi="isCreateSktLoi" 
        @changeIsCreateProcurementApproval="changeIsCreateProcurementApproval" 
        @changeIsContinueDraft="changeIsContinueDraft"
        @changeSubmittedProcurementApproval="changeSubmittedProcurementApproval"
        :isContinueDraft="isContinueDraft"
        :isSingleVendor="isSingleVendor"
      />
      <SktLoiDetails v-if="submittedSktLoi"/>
    </div>
    <div v-if="!sktLoiShow"
      class="flex items-center justify-center height-custom">
      <div class="flex">
        <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
        <p class="text-base font-medium text-gray">Proses Procurement Approval belum di mulai</p>
      </div>
    </div>
  </div>
</template>

<script>
import CreateSktLoi from '@/core/components/rfp/sktLoi/CreateSktLoi.vue'
import SktLoiDetails from '@/core/components/rfp/sktLoi/SktLoiDetails.vue'
import ContinueDraftApproval from '@/core/components/rfp/procurementApproval/ContinueDraftApproval.vue'

export default {
  name: "SktLoi",
  data() {
    return {
      isCreateSktLoi: false,
      isContinueDraft: false,
      submittedSktLoi: false,
      isSingleVendor: false
    };
  },
  computed: {
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
    currentStatusProject() {
      return this.projectLogRfp?.rfpLogs[this.projectLogRfp?.rfpLogs?.length - 1]?.statusProject
    },
    sktLoiShow() {
      return true
    },
    loading: {
      get() {
        return this.$store.state.modal.loading
      },
      set(value) {
        this.$store.commit('modal/SET_LOADING', value)
      }
    },
  },
  methods: {
    changeIsCreateProcurementApproval() {
      this.isCreateSktLoi = !this.isCreateSktLoi
    },
    changeIsContinueDraft() {
      this.isContinueDraft = !this.isContinueDraft
    },
    changeSubmittedProcurementApproval() {
      this.submittedSktLoi = !this.submittedSktLoi
    },
    continueDraft() {
      this.changeIsContinueDraft()
      this.changeIsCreateProcurementApproval()
    }
  },
  async mounted() {
    this.loading.loadingProjectLog = true
    const res = await this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
    if (res?.status === 200) {
      this.loading.loadingProjectLog = false
    }
  },
  components:{
    CreateSktLoi,
    ContinueDraftApproval,
    SktLoiDetails
  }
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
</style>