<template>
  <div>
    <div class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
      <div class="border-gray-light">
        <div class="p-2">
          <p class="text-sm font-medium text-gray">Checker</p>
        </div>
      </div>
      <table class="w-full" aria-describedby="checker">
        <thead>
          <tr class="bg-primary-lightest">
            <th scope="col" class="w-1/3 p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">GSIT</p>
            </th>
            <th scope="col" class="w-1/3 p-2 border-t border-l border-gray-light">
              <p class="text-sm font-medium text-left text-gray">DLOG</p>
            </th>
            <th scope="col" class="w-1/3 p-2 border-t border-l border-gray-light">
              <p class="text-sm font-medium text-left text-gray">UKKP</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="w-1/3 p-2 border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span> 
              </p>
            </td>
            <td class="w-1/3 p-2 border-l border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span>
              </p>
            </td>
            <td class="w-1/3 p-2 border-l border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span> 
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
  
    </div>
    <div class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
      <div class="border-gray-light">
        <div class="p-2">
          <p class="text-sm font-medium text-gray">Recomendation</p>
        </div>
      </div>
      <table class="w-full" aria-describedby="recomendation">
        <thead>
          <tr class="bg-primary-lightest">
            <th scope="col" class="w-1/3 p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">GSIT</p>
            </th>
            <th scope="col" class="w-1/3 p-2 border-t border-l border-gray-light">
              <p class="text-sm font-medium text-left text-gray">DLOG</p>
            </th>
            <th scope="col" class="w-1/3 p-2 border-t border-l border-gray-light">
              <p class="text-sm font-medium text-left text-gray">UKKP</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="w-1/3 p-2 border-b border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span> 
              </p>
            </td>
            <td class="w-1/3 p-2 border-b border-l border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span>
              </p>
            </td>
            <td class="w-1/3 p-2 border-b border-l border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span> 
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span>
              </p>
            </td>
          </tr>
          <tr>
            <td class="w-1/3 p-2 border-r border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span> 
              </p>
            </td>
            <td class="w-1/3 p-2 border-r border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span>
              </p>
            </td>
            <td class="w-1/3 p-2 border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span> 
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span>
              </p>
            </td>
          </tr>
          
        </tbody>
      </table>
  
    </div>
    <div class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
      <div class="border-gray-light">
        <div class="p-2">
          <p class="text-sm font-medium text-gray">Approval</p>
        </div>
      </div>
      <table class="w-full" aria-describedby="approval">
        <thead>
          <tr class="w-1/3 bg-primary-lightest">
            <th scope="col" class="p-2 border-t border-r border-gray-light">
              <p class="text-sm font-medium text-left text-gray">GSIT</p>
            </th>
            <th scope="col" class="p-2 border-t border-r border-gray-light">
              <p class="text-sm font-medium text-left text-gray">DLOG</p>
            </th>
            <th scope="col" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">UKKP</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="w-1/3 p-2 border-r border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span> 
              </p>
            </td>
            <td class="w-1/3 p-2 border-r border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span>
              </p>
            </td>
            <td class="w-1/3 p-2 border-gray-light">
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">asdf</p>
              <p v-if="waiting" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">Waiting</p>
              <p 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="textStatusApproved('Approved')">Approved</span>
                <span class="px-2 font-black text-gray">&#183;</span> 
                <span class="whitespace-nowrap">21 Agustus 2023 12:00</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
  
    </div>
  </div>
</template>
<script>

export default {
  name: 'TableApprovalProcurement',
  data() {
    return {
      waiting: false
    }
  },
  methods: {
    textStatusApproved(status) {
      if (status?.toLowerCase().includes('approved')) {
        return 'text-success'
      } else {
        return 'text-error'
      }

    },
  },
  components: {
  }
}
</script>