<template>
  <div>
    <ButtonGista type="success" class="w-full" color="secondary" @click="toggle">Submit Changes</ButtonGista>
   
    <GistaModal :value="isOpen"
      width="2/3"
      scrollable
      @input="toggle"
      customClass="max-w-2xl bg-white">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="w-full p-5">
            <div class="flex justify-center w-full">
              <h5 class="text-lg font-semibold text-gray">Ubah Approvers</h5>
            </div>
            <div class="flex-wrap w-full mb-4">
              <ValidationProvider name="Reason" rules="required|max:300" v-slot="{errors}">
                <TextareaGista
                  label="Reason"
                  placeholder="Leave a comment here"
                  v-model="reason"
                  :error="!!errors[0]"
                  mandatory
                >
                  <template #message>
                    <p>{{errors[0]}}</p>
                  </template>
                </TextareaGista>
              </ValidationProvider>

            </div>
            <div class="flex justify-center">
              <ButtonGista 
                type="primary"
                color="success"
                @click="handleSubmit(clickSubmit)"
                customClass="w-full sm:w-56">
                Submit Changes
              </ButtonGista>

            </div>
          </div>
        </ValidationObserver>
    </GistaModal>
  </div>
</template>

<script>
export default {
  name: 'SubmitChangeApprovals',
  props: ['isOpen'],
  methods: {
    toggle() {
      this.$emit('clickToggle')
    },
    clickSubmit() {
      this.$emit('click')
    }
  }
}
</script>