var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.data),function(n,i){return _c('div',{key:i,staticClass:"border border-gray-lightest rounded-lg p-3.5 mb-6"},[_c('div',{staticClass:"flex gap-x-6 gap-y-3.5",class:_vm.projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row justify-between'},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"text-lg icon-file-text text-gray",class:{ 'mr-6': !_vm.submitted, 'mr-3.5': _vm.submitted}}),_c('div',[_c('div',[(n.isLocalPartner)?_c('h5',{staticClass:"text-sm text-gray"},[_c('span',{staticClass:"text-tertiary"},[_vm._v("Local Partner #"+_vm._s(n.sequence))]),_vm._v(" of "+_vm._s(n.vendorPrincipal))]):_c('div',{staticClass:"text-sm text-tertiary"},[_vm._v("Vendor #"+_vm._s(n.sequence))])]),_c('div',[_c('h5',{staticClass:"text-gray-dark"},[_vm._v(_vm._s(n.vendorName))])])])]),_c('div',{staticClass:"flex items-center gap-3.5"},[(_vm.submitted)?_c('ButtonGista',{class:_vm.projectDetailContainerWidth < 640 ? 'w-full' : '',attrs:{"type":"secondary","color":"success-dark","size":"small"},on:{"click":_vm.clickViewLoi},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c('span',{staticClass:"mr-2.5 text-lg icon-eye text-success"})]},proxy:true}],null,true)},[_vm._v(" View LOI ")]):_vm._e(),_c('p',{staticClass:"text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray",class:!n.collapse ? 'rotate-180' : 'rotate-0',on:{"click":function($event){return _vm.changeCollapse(i)}}})],1)]),(!n.collapse)?_c('div',[_c('div',{staticClass:"grid gap-x-6 gap-y-3.5 my-3.5",class:{
            'grid-cols-1': _vm.projectDetailContainerWidth < 768,
            'grid-cols-2': _vm.projectDetailContainerWidth >= 768
        }},[_c('InputDisabled',{class:{
              'col-span-2': _vm.projectDetailContainerWidth < 768,
              'col-span-1': _vm.projectDetailContainerWidth >= 768
            },attrs:{"label":"Vendor Name","value":n.vendorName}}),_c('InputDisabled',{class:{
              'col-span-2': _vm.projectDetailContainerWidth < 768,
              'col-span-1': _vm.projectDetailContainerWidth >= 768
            },attrs:{"label":"Status","value":n.status}}),_c('InputDisabled',{class:{
              'col-span-2': _vm.projectDetailContainerWidth < 768,
              'col-span-1': _vm.projectDetailContainerWidth >= 768
            },attrs:{"label":"LOI No.","value":n.loiNo}}),_c('InputDisabled',{class:{
              'col-span-2': _vm.projectDetailContainerWidth < 768,
              'col-span-1': _vm.projectDetailContainerWidth >= 768
            },attrs:{"label":"LOI Date","value":n.loiDate}}),_c('InputDisabled',{class:{
              'col-span-2': _vm.projectDetailContainerWidth < 768,
              'col-span-1': _vm.projectDetailContainerWidth >= 768
            },attrs:{"label":"Offer No.","value":n.offerNo}}),_c('InputDisabled',{class:{
              'col-span-2': _vm.projectDetailContainerWidth < 768,
              'col-span-1': _vm.projectDetailContainerWidth >= 768
            },attrs:{"label":"Offer Date","value":n.offerDate}}),_c('InputDisabled',{class:{
              'col-span-2': _vm.projectDetailContainerWidth < 768,
              'col-span-1': _vm.projectDetailContainerWidth >= 768
            },attrs:{"label":"Final Negotiation Date","value":n.finalNegotiationDate}}),_c('div',{staticClass:"col-span-2 pt-3.5 border-t border-gray-lightest"},[_c('TableLoi')],1),_c('div',{staticClass:"col-span-2"},[_vm._m(0,true),_c('div',{},[(n.termOfPayment)?_c('TiptapView',{model:{value:(n.termOfPayment),callback:function ($$v) {_vm.$set(n, "termOfPayment", $$v)},expression:"n.termOfPayment"}}):_c('InputDisabled',{attrs:{"value":"-"}})],1)]),_c('div',{staticClass:"col-span-2"},[_vm._m(1,true),_c('div',{},[(n.noteNegotiation)?_c('TiptapView',{model:{value:(n.noteNegotiation),callback:function ($$v) {_vm.$set(n, "noteNegotiation", $$v)},expression:"n.noteNegotiation"}}):_c('InputDisabled',{attrs:{"value":"-"}})],1)]),(false)?_c('div',[_c('ValidationProvider',{attrs:{"name":`picVendor`,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('InputSolutip',{attrs:{"type":"text","label":"PIC Vendor","size":"large","mandatory":true,"error":errors[0]},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',[_vm._v(_vm._s(errors[0]))])]},proxy:true}],null,true),model:{value:(n.picVendor),callback:function ($$v) {_vm.$set(n, "picVendor", $$v)},expression:"n.picVendor"}})]}}],null,true)})],1):_vm._e(),(true)?_c('div',[_c('InputDisabled',{attrs:{"label":"PIC Vendor","value":n.picVendor}})],1):_vm._e()],1),(!_vm.submitted)?_c('div',{staticClass:"flex justify-end border-t border-gray-lightest pt-3.5"},[_c('ButtonGista',{attrs:{"type":"secondary","color":"primary"},on:{"click":_vm.clickPreviewLoi}},[_vm._v("Preview LOI")])],1):_vm._e()]):_vm._e()])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block mb-1 text-sm text-gray"},[_vm._v(" Term of Payment ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block mb-1 text-sm text-gray"},[_vm._v(" Note Negotiation / BCA Request ")])])
}]

export { render, staticRenderFns }