<template>
  <div class="w-full">
    <div class="grid gap-x-6 gap-y-2.5 mb-2.5"
      :class="{
        'grid-cols-1': projectDetailContainerWidth < 768,
        'grid-cols-2': projectDetailContainerWidth >= 768
      }"
    >
      <InputDisabled 
        label="Project ID" 
        value="PRJ22001"
      />
      <InputDisabled 
        label="Request No."
        value="001/RFP/ITP-DLOG/2023"
      />
    </div>
    <div class="mb-6">
      <InputDisabled 
        label="Project Name"
        value="Test Project Name"
      />
    </div>
    <div class="col-span-2 mb-6">
      <div class="flex items-center">
        <label class="block mb-1 text-sm text-gray">
          Note Internal BCA
        </label>
      </div>
      <div class="">
        <Tiptap v-if="!readOnly" />
        <TiptapView v-else-if="readOnly" v-model="approval.internalNnote" />
        <InputDisabled value="-" v-else/>
      </div>
    </div>
    <div class="col-span-2 mb-6">
      <div class="flex items-center">
        <label class="block mb-1 text-sm text-gray">
          Note for Bank Guarantee
        </label>
      </div>
      <div class="">
        <Tiptap v-if="!readOnly" />
        <TiptapView v-else-if="readOnly" v-model="approval.bankGuaranteeNote" />
        <InputDisabled value="-" v-else/>
      </div>
    </div>
    <div class="col-span-2 mb-6" v-for="n,i in approval?.attachments" :key="i">
      <div>
        <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
      </div>
      <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
        <p class="text-base text-gray truncate">{{ n.fileName }}</p>
        <span class="cursor-pointer icon-trash-2 text-error" @click="clickRemoveFileAttachment(i)"></span>
      </div>
    </div>
    <div class="col-span-2 py-3.5 mb-6" v-if="!readOnly">
      <ValidationProvider
        ref="lampiranApproval"
        >
        <div class="flex flex-wrap items-center">
          <div class="flex" v-if="maxAttachment">
            <span class="mr-2 icon-file-plus text-secondary"></span>
            <input type="file"
              id="upload-file-attachment"
              ref="fileAttachment"
              hidden
              @change="addAttachment($event, 'lampiranApproval')" />
            <label for="upload-file-attachment"
              class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
              Add Attachment
            </label>
          </div>
          <p class="mr-3.5" :class="{ 'text-error': errorFileType.attachment, 'text-gray': !errorFileType.attachment }">Max Attachment: 10 </p>
          <p :class="{ 'text-error': errorFileType.attachment, 'text-gray': !errorFileType.attachment }">Supported file: .pdf, max file size: 10 MB</p>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import TiptapView from '@/core/components/custom/TiptapView.vue'
import Tiptap from '@/core/components/custom/Tiptap.vue'

export default {
  name: "InformationLoi",
  props: ['readOnly'],
  data(){
    return {
      approval: {
        attachments: [],
        division: '',
        internalNnote: '',
        bankGuaranteeNote: ''
      },
      errorFileType: false,
      divisions: []
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    maxAttachment() {
      return this.approval.attachments.length < 10
    },
  },
  methods: {
    async clickRemoveFileAttachment(index){
      try {
        this.approval.attachments = this.approval.attachments.filter((e,i) => i !== index)
      } catch (error) {
        console.log(error)
      }
    },
    async addAttachment(e, fileRef) {
      const file = e.target?.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
      if(checkFileValidation){
        this.errorFileType = true
      } else {
        try {
          const fileAfterUpload = {fileName: file.name}
          this.approval.attachments = [ ...this.approval.attachments, fileAfterUpload ]
          this.errorFileType = false
        } catch (error) {
          console.log(error)
        }
        
      }
      if (this.$refs[fileRef]) {
        this.$refs[fileRef].value = ''
      }
    },
    focusRecommendation() {
      this.divisions = [
        { fullName: 'Testing' },
        { fullName: 'Testing 1' },
        { fullName: 'Testing 2' },
        { fullName: 'Testing 3' },
      ]
    },
    changeRecommendation(e) {
      this.approval.division = e.fullName
    },
    inputSearch(e) {
      console.log('search', e)
    },
  },
  mounted(){
    if(this.readOnly) {
      this.approval= {
        attachments: [{fileName: 'File.pdf'}],
        introduction: 'Ini contoh field text area yang sudah terisi value.',
        projectGoals: 'Ini contoh field text area yang sudah terisi value.',
        internalNote: 'Ini contoh field text area yang sudah terisi value.'
      }
    }
  },
  components:{
    InputDisabled,
    Tiptap,
    TiptapView
  }
}
</script>