<template>
  <div class="height-custom">
    <div v-if="!isLoading">
      <SendProposalITP v-if="menuQualificationActionSendProposal?.canCreate && qualificationSendProposalITPShow" />
      <div v-if="(!eliminatedVendorExist && !qualificationSendProposalITPShow)" 
        class="flex items-center justify-center height-custom">
        <div class="flex">
          <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
          <p class="text-base font-medium text-gray">Proses Qualification belum di mulai</p>
        </div>
      </div>
      <SendProposalUser v-if="!menuQualificationActionSendProposal?.canCreate && qualificationSendProposalITPShow" />
      <QualificationEliminated />
      
      <!-- send proposal -->
      <div class="flex flex-col sm:flex-row" :class="sendProposalStatusProject? 'justify-between':'justify-end'" v-if="menuQualificationActionSendProposal?.canCreate">
        <div class="flex items-center" v-if="sendProposalStatusProject">
          <p class="mr-3 text-2xl icon-info text-warning"></p>
          <p class="mr-4 text-sm text-warning">Silakan unggah dokumen proposal yang diperlukan untuk setiap kandidat vendor</p>
        </div>
        <ProposalSend v-if="isEnableSendProposal" />
      </div>
    </div>
    <QualificationLoading v-else/>
  </div>
</template>

<script>
import SendProposalITP from '@/core/components/rfp/SendProposalITP.vue';
import SendProposalUser from '@/core/components/rfp/SendProposalUser.vue';
import ProposalSend from '@/core/components/modal/ProposalSend'
import QualificationEliminated from '@/core/components/rfp/QualificationEliminated.vue';
import { GSIT_02_AWAITING_DOCUMENT_REVIEW, UKKP_02_AWAITING_DOCUMENT_REVIEW, VENDOR_02_AWAITING_PROPOSAL_PRICE_DOCUMENT, COMPLETED, CANCELED } from '@/core/constant/statusProject'
import { QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED} from '@/core/constant/qualification.js'
import QualificationLoading from '@/core/components/rfp/loading/QualificationLoading.vue';

export default {
  name: "Qualification",
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    menu() {
			return this.$store.state.dashboard.menu
		},
    isEnableSendProposal() {
      return this.$store.state.qualification.isEnableSendProposal
    },
    qualifications: {
			get() {
				return this.$store.state.qualification.qualifications
			},
			set(value) {
				this.$store.commit('qualification/SET_QUALIFICATOINS', value)
			}
		},
    loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
    menuQualificationActionSendProposal() {
			return this.$store.getters['dashboard/menuQualificationActionSendProposal']
		},
    qualificationSendProposalITPShow() {
      const statusProject = [ GSIT_02_AWAITING_DOCUMENT_REVIEW, UKKP_02_AWAITING_DOCUMENT_REVIEW, VENDOR_02_AWAITING_PROPOSAL_PRICE_DOCUMENT, COMPLETED, CANCELED ]
      return this.projectLogRfp?.rfpLogs.some(e => statusProject.includes(e.statusProject))
    },
    sendProposalStatusProject() {
      const statusProject = [VENDOR_02_AWAITING_PROPOSAL_PRICE_DOCUMENT,]
      return statusProject.includes(this.currentStatusProject)
    },
    projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
    currentStatusProject() {
      return this.projectLogRfp?.rfpLogs[this.projectLogRfp?.rfpLogs?.length - 1]?.statusProject
    },
    eliminatedVendorExist() {
      const eliminateVendors = this.qualifications.filter(e => e.statusVendor === QUALIFICATION_ELIMINATED || e.statusVendor === PRA_QUALIFICATION_ELIMINATED)
			return eliminateVendors.length > 0
    },
  },
  async mounted() {
    this.isLoading = true
    this.loading.loadingProjectLog = true
    const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
		console.log(res, res?.data.code, 'get access');
    if (res?.data.code !== 200) return
    const r1 = this.$store.dispatch('projectDetail/getProposalInformation', this.$route.params.projectId)
    const r2 = this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
    const r3 = this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
    const [res1, res2, res3] = await Promise.all([r1, r2, r3])
    if (res1?.status === 200 && res2?.status === 200) {
      this.isLoading = false
    }
    if (res3?.status === 200) {
      this.loading.loadingProjectLog = false
    }

  },
  components: {
    SendProposalITP,
    SendProposalUser,
    QualificationEliminated,
    ProposalSend,
    QualificationLoading,
  }
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
</style>