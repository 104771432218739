import { render, staticRenderFns } from "./TableLoi.vue?vue&type=template&id=5ae57a8a&scoped=true"
import script from "./TableLoi.vue?vue&type=script&lang=js"
export * from "./TableLoi.vue?vue&type=script&lang=js"
import style0 from "./TableLoi.vue?vue&type=style&index=0&id=5ae57a8a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae57a8a",
  null
  
)

export default component.exports