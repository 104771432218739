<template>
	<div class="">
		<div class="w-full overflow-auto max-h-custom">
			<div class="w-full">
				<table class="relative w-full border-collapse" aria-describedby="negotiationTable">
					<thead class="sticky top-0 z-20 h-10 py-1 bg-white">
						<tr class="truncate">
							<th class="p-0" scope="col">
								<div class="h-10 border-b-2 border-primary"></div>
							</th>
							<th class="p-0" scope="col" >
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">No</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">ID Product</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Type</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Product/Service</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Sub Product</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Description</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Location</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Qty</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">UoM</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Period</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Currency</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Monthly Cost</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Unit Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Total Unit Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Disc %</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Disc Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Final Total Price</h5>
							</th>
						</tr>
					</thead>
					<tbody v-for="n,i in negotiationDetails.offers" :key="i">
						<template>
							<tr class="sticky z-10 h-10 py-1 cursor-pointer top-10 bg-primary-lightest" 
								@click="toggleCollapse(i)">
								<td class="w-max py-2 px-2.5 cursor-pointer" colspan="1">
									<span v-if="!collapse[i]"
										class="mr-3 text-base font-bold cursor-pointer icon-plus text-gray"></span>
									<span v-else
										class="mr-3 text-base font-bold cursor-pointer icon-minus text-gray"></span>
								</td>
								<td class="relative" colspan="17">
									<div class="flex items-center h-10">
										<h5 class="mr-4 text-sm text-gray-darkest">{{n.title}}</h5>
										<TooltipSolutip :dataTitle="`Uploaded Date: ${n.dateUploaded}`">
											<p class="mr-4 text-lg icon-info text-gray"></p>
										</TooltipSolutip>
										<button-extra-small-solutip
											v-if="menuRfpNegotiationOfferSave?.canCreate && showEdit(n, i)"
											:color="showContinueDraft(n) ? 'success' : 'secondary'"
											@click.stop="clickEditDetail(n, i)"
											class="mr-4"
											:disabled="negotiationDetails?.isCanceled"
										>
											{{showContinueDraft(n) ? 'Continue Draft' : 'Edit Detail'}}
										</button-extra-small-solutip>
										<button-extra-small-solutip v-if="menuRfpNegotiationOfferSave?.canCreate && showEditOffer" color="secondary" @click.stop="clickEdit(i)" class="mr-4" :disabled="negotiationDetails?.isCanceled">Edit Offer</button-extra-small-solutip>
										<button-extra-small-solutip v-if="n.isBanSubmitted && menuRfpNegotiationDetailsBan?.canRead" color="tertiary" @click.stop="clickView(i, n.negotiationId, n.statusDeal)">View</button-extra-small-solutip>
										<button-extra-small-solutip v-if="!isRejected && !n.isBanSubmitted && menuRfpNegotiationOfferDelete?.canDelete && notContinueDraft && !n.isDraftAfterSubmit" color="error" @click.stop="openModalDelete(i)" :disabled="negotiationDetails?.isCanceled">Delete</button-extra-small-solutip>
									</div>
								</td>
							</tr>
							<template v-for="(item, j) in n.offerDetails">
								<tr v-show="collapse[i]" v-if="item" class="h-10 truncate" :key="j">
									<td class="w-max flex items-center px-2.5">
										<span class="mr-3 text-base font-bold text-white icon-plus"></span>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ item.no ?? '-' }}</h5>
									</td>
									<td>
										<h5 v-if="item.productId?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.productId.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.productId)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.productId }}</h5>
									</td>
									<td>
										<h5 v-if="item.jenis?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.jenis.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.jenis)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.jenis }}</h5>
									</td>
									<td>
										<h5 v-if="item.productLayanan?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.productLayanan.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.productLayanan)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.productLayanan }}</h5>
									</td>
									<td>
										<h5 v-if="item.subProduct?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.subProduct.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.subProduct)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.subProduct }}</h5>
									</td>
									<td>
										<h5 v-if="item.description?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.description.slice(0,20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.description)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.description }}</h5>
									</td>
									<td>
										<h5 v-if="item.location?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.location.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.location)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.location }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ formatCurrency(item.qty, item.currency) }}</h5>
									</td>
									<td>
										<h5 v-if="item.uom?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.uom.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.uom)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.uom }}</h5>
									</td>
									<td>
										<h5 v-if="item.period?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.period.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.period)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.period }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ item.currency }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.biayaPerBulan, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.unitPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.totalUnitPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.discount, item.currency) }}%</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.discountPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.finalTotalPrice, item.currency) }}</h5>
									</td>
								</tr>
							</template>
							<template v-if="n.costType !== RATE_COST">
								<tr v-show="collapse[i]" class="bg-gradient-to-t from-primary-lightest to-white">
									<td class="p-0"><div class="h-10 border-b-2 border-primary"></div></td>
									<td class="p-0">
										<div class="flex items-center h-10 border-b-2 border-primary">
											<span class="text-base icon-corner-down-right"></span>
										</div>
									</td>
									<td class="p-0" colspan="17">
										<div class="flex items-center h-10 border-b-2 border-primary">
											<p class="mr-12 text-sm font-medium text-gray-darkest">Total Price: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.totalPrice, totalCurrency(n)) }}</span></p>
											<p v-if="!vatIncludedChecker(n?.taxType)" class="mr-12 text-sm font-medium text-gray-darkest">VAT ({{ n?.taxTypePercentage }}%): <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.ppn, totalCurrency(n)) }}</span></p>
											<p v-else class="mr-12 text-sm font-medium text-gray-darkest">{{ n?.taxType }}</p>
											<p class="mr-12 text-sm font-medium text-gray-darkest">Total Price + VAT: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.grandTotal, totalCurrency(n)) }}</span></p>
										</div>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
				</table>
				<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="modalText"/>
				<ModalConfirm 
					title="Hapus?" 
					message="Semua informasi akan dihapus, apakah Anda yakin?" 
					icon="rejected" 
					textButton="Delete" 
					:isOpen="isOpenDelete" 
					@changeIsOpen="changeIsOpen($event)"
					@click="clickDelete"
					colorButton="error"></ModalConfirm>
			</div>
		</div>
		<div v-if="negotiationDetails?.offers?.length === 0" class="flex items-center justify-center w-full py-6">
			<h4 class="text-sm font-medium text-warning-darkest">
				No data. Please upload the initial offer from vendor.
			</h4>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import ButtonExtraSmallSolutip from '@/core/components/custom/ButtonExtraSmall.vue'
import TooltipSolutip from '@/core/components/custom/Tooltip.vue'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import ModalConfirm from '@/core/components/modal/Confirm.vue'

export default {
	name: 'NegotiationTable',
	data() {
		return {
			collapse: [],
			modalText: '',
			readMoreShow: false,
			RATE_COST: 'RATE_COST',
			isOpenDelete: false,
			indexDelete: 0,
			maxLength: 34
		}
	},
	computed: {
		vendorDetail() {
      return this.$store.state.negotiation.vendorDetail
    },
		negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
		menuRfpNegotiationOfferSave() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferSave']
		},
		menuRfpNegotiationOfferDelete() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferDelete']
		},
		menuRfpNegotiationDetailsBan() {
			return this.$store.getters['dashboard/menuRfpNegotiationDetailsBan']
    },
		notContinueDraft() {
			if (this.negotiationDetails.isEnableContinueDraftBanReviseOffer || this.negotiationDetails.isEnableContinueDraftBanDeal) {
				return false
			}
			return true
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
		isRejected() {
			const data = this.negotiationDetails?.logApprovalStatus
			return data ? data.toLowerCase() === 'rejected' : false
		},
		showEditOffer() {
			return this.negotiationDetails.negotiationStatus !== "Final Deal" || this.negotiationDetails?.isEnableRenegotiation
		},
	},
	watch: {
		negotiationDetails() {
			this.collapse = this.negotiationDetails?.offers?.map((e,i) => (i === this.negotiationDetails?.offers?.length -1))
		}
	},
	methods: {
		formatCurrency,
    vatIncludedChecker,
		toggleCollapse(index) {
			this.collapse = this.collapse.map((e,i) => (i === index? !e : e))
		},
		openModalDelete(index) {
			this.isOpenDelete = true
			this.indexDelete = index
		},
		closeModal() {
			this.readMoreShow = false
		},
		changeIsOpen(val) {
			this.isOpenDelete = val
		},
		async clickDelete() {
			console.log('delete', this.indexDelete);
			try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('negotiation/deleteNegotiationOffer', { negotiationId: this.negotiationDetails.offers[this.indexDelete].negotiationId })
				if (response?.status < 300 && response?.status >= 200) {
					this.modal.modalLoading = false
					this.changeIsOpen(false)
					this.$store.dispatch('negotiation/getNegotiationDetails', { vendorCandidateId: this.vendorDetail })
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
			}
		},
		clickEdit(index) {
			console.log('edit', index);
			this.$store.commit('negotiation/SET_SHOW_EDIT_OFFER', index)
		},
		clickView(i, negotiationId, statusDeal) {
			const OFFER_REVISION = 'Offer Revision'
			// this.$store.commit('negotiation/SET_BAN_NEGOTIATION_ID', negotiationId)
			if (statusDeal === OFFER_REVISION) {
				// this.$store.commit('negotiation/SET_SHOW_DETAIL_OFFER', true)
				this.$router.push({ name: 'projectNegotiation', query: { tab: 'BAN', detail: 'offer', negotiation: negotiationId }}).catch(() => ({}))
			} else {
        // this.$store.commit('negotiation/SET_SHOW_DETAIL_BAN', true)
				this.$router.push({ name: 'projectNegotiation', query: { tab: 'BAN', negotiation: negotiationId }}).catch(() => ({}))

			}
		},
		readMore(text) {
			this.readMoreShow = true;
			this.modalText = text
		},
		totalCurrency(n) {
			return n.offerDetails?.[0]?.currency
		},
		clickEditDetail(n, idx) {
			if(n.title.includes('Final Deal')) {
				if(!this.negotiationDetails.isEnableContinueDraftBanDeal) this.$emit('clickEditBan')
				else this.$emit('clickToggle', true)
			} else {
				this.$store.commit('negotiation/SET_SELECTED_IDX_OFFER', idx)
				if(this.showContinueDraft(n)) this.$emit('reviseDraft', n.lastModifiedDateBan)
				else this.$emit('clickRevise')
			}
		},
		showContinueDraft(n) {
			return !n.isBanSubmitted
		},
		showEdit(n, i) {
			const negotiationDetails = this.negotiationDetails
			const condition = negotiationDetails.isEnableUploadOffer ? true : i !== negotiationDetails.offers.length - 1
			return ((negotiationDetails.negotiationStatus !== 'Final Deal' && n.isBanDeal === false && condition )|| negotiationDetails?.isEnableRenegotiation)
		},
	},
	mounted() {
		this.collapse = this.negotiationDetails?.offers?.map((e,i) => (i === this.negotiationDetails?.offers?.length -1))
	},
	components: {
		ButtonExtraSmallSolutip,
		TooltipSolutip,
		ModalReadMore,
		ModalConfirm
	}


}
</script>

<style scoped>
.max-h-custom {
	max-height: 24rem;
}

</style>