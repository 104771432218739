<template>
  <div class="w-full">
    <div class="relative flex w-full my-6" :class="projectDetailContainerWidth < 640 && !isSingleVendor ? 'gap-2.5 flex-col items-start' : 'items-center '">
      <span class="text-2xl cursor-pointer icon-arrow-left text-gray" @click="changeIsCreateProcurementApproval"></span>
      <div class="flex justify-center w-full">
          <ThreeButton :options="edit ? optionEditTab : optionsTab"
          :disabled="[false, !isCreateLoi]"
          @click="changeActiveButton"
          v-if="!isSingleVendor"
          :value="activeBtn"
          :class="{'w-min min-w-custom': projectDetailContainerWidth > 640}" />
          <div v-else>
            <h4 class="text-lg font-medium text-gray-darkest">Create LOI</h4>
          </div>
      </div>
      <div v-if="isCreateLoi && showLoi && !edit" class="absolute right-0 text-base font-medium cursor-pointer text-error" @click="cancelLoi" >
        <span class="w-6 icon-x"></span>
        Cancel LOI
      </div>
    </div>
    <CreateLoi v-if="showLoi" />
    <CreateSkt v-if="showSkt" />
    
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex justify-between" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
        <SaveAsDraftSktLoi v-if="isCreateLoi ? !showSkt : true" @click="saveAsDraft" />
        <div class="flex" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
          <LoiNotCreated @click="toggleIsNeedLoi" v-if="!isNeedLoi && !isCreateLoi" />
          <NeedToCreateLoi v-if="isNeedLoi && !edit && !isSingleVendor" @click="handleClickNeedLoi" />
          <SubmitSktLoi v-else-if="edit || isSingleVendor ? true : isCreateLoi ? !showSkt : !isNeedLoi" :msg="isSingleVendor ? 'LOI' : showLoi ? 'SKT & LOI' : 'SKT'" @click="submitSktLoi" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SaveAsDraftSktLoi from '@/core/components/rfp/sktLoi/SaveAsDraftSktLoi.vue'
import SubmitSktLoi from '@/core/components/rfp/sktLoi/SubmitSktLoi.vue'
import CreateSkt from '@/core/components/rfp/sktLoi/CreateSkt.vue'
import CreateLoi from '@/core/components/rfp/sktLoi/CreateLoi.vue'
import NeedToCreateLoi from '@/core/components/rfp/sktLoi/NeedToCreateLoi.vue'
import LoiNotCreated from '@/core/components/rfp/sktLoi/LoiNotCreated.vue'
import ThreeButton from '@/core/components/custom/ThreeButton.vue';
import { MESSAGE_SUCCESS_CREATE_SKT, MESSAGE_SUCCESS_CREATE_SKT_LOI } from '@/core/constant/successMessage'

export default {
  name: "CreateSktLoi",
  props: ['isContinueDraft', 'isSingleVendor'],
  data() {
    return {
      optionsTab: ['Create SKT', 'Create LOI'],
      optionEditTab: ['Edit SKT', 'Edit LOI'],
      activeButton: 'Create SKT',
      activeEditButton: 'Edit SKT',
      collapse: {
      },
      edit: false,
      isCreateLoi: false,
      isNeedLoi: true
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    showSkt() {
      return this.activeBtn === this.createSkt
    },
    showLoi() {
      return this.activeBtn === this.createLoi
    },
    createLoi() {
      return `${this.edit ? 'Edit' : 'Create'} LOI`
    },
    createSkt() {
      return `${this.edit ? 'Edit' : 'Create'} SKT`
    },
    activeBtn: {
      get() {
        return this.edit ? this.activeEditButton : this.activeButton
      },
      set(value) {
        if(this.edit) this.activeEditButton = value
        else this.activeButton = value
      }
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
  },
  methods:{
    changeActiveButton(e) {
      this.activeBtn = e
    },
    toggleIsNeedLoi() {
      this.isNeedLoi = !this.isNeedLoi
    },
    handleClickNeedLoi(e) {
      this.isCreateLoi = e
      if(e) this.activeBtn = this.createLoi
      
      this.isNeedLoi = false
    },
    cancelLoi() {
      this.isCreateLoi = false
      this.isNeedLoi = true
      this.activeBtn = this.createSkt
    },
    //function collapse/extend
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key]
    },
    saveAsDraft() {
      try {
        this.$emit('changeIsCreateProcurementApproval')
        this.$emit('changeIsContinueDraft')
      } catch (e) {
        console.log(e)
      }
    },
    submitSktLoi() {
      try {
        if (this.isContinueDraft) {
          this.$emit('changeIsCreateProcurementApproval')
          this.$emit('changeIsContinueDraft')
        } else {
          this.$emit('changeSubmittedProcurementApproval')
        }
        this.modal.modalSuccessSubmitted = { value: true, title: this.showLoi ? MESSAGE_SUCCESS_CREATE_SKT_LOI : MESSAGE_SUCCESS_CREATE_SKT }
      } catch (e) {
        console.log(e)
      }
    },
    changeIsCreateProcurementApproval() {
      this.$emit('changeIsCreateProcurementApproval')
    },
    changeSubmittedProcurementApproval() {
      this.$emit('changeSubmittedProcurementApproval')
    },
    clickPreviewBan() {
      console.log('clicked')
    },
  },
  components:{
    SubmitSktLoi,
    SaveAsDraftSktLoi,
    ThreeButton,
    CreateLoi,
    CreateSkt,
    NeedToCreateLoi,
    LoiNotCreated
  }
}
</script>

<style scoped>
.min-w-custom {
	min-width: 20rem;
}
</style>