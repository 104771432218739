<template>
  <div class="w-full">
    <div class="grid gap-x-6 gap-y-3.5 mb-3.5 "
      :class="{
        'grid-cols-1': projectDetailContainerWidth < 768,
        'grid-cols-2': projectDetailContainerWidth >= 768
      }"
    >
      <template>
        <AutocompleteNewSolutip
          v-if="!disabled"
          label="Approval 1"
          placeholder="Search"
          :options="approvalList"
          @updateInput="inputSearch($event, 'approval1')"
          @updateAutoComplete="updateApproval($event, 'approval1')"
          @focus="focusApproval"
          @blur="blurApproval1"
          v-model="approval.approval1"
          textField="fullName"
          iconLeft="icon-search text-xl text-gray-light"
          mandatory
        >
        </AutocompleteNewSolutip>
        <InputDisabled 
          v-else
          label="Approval 1" 
          value="John Doe"
        />
      </template>
      <template>
        <AutocompleteNewSolutip
          v-if="!disabled"
          label="Approval 2"
          placeholder="Search"
          :options="approvalList"
          @updateInput="inputSearch($event, 'approval2')"
          @updateAutoComplete="updateApproval1($event, 'approval2')"
          @focus="focusApproval"
          @blur="blurApproval2"
          v-model="approval.approval2"
          textField="fullName"
          iconLeft="icon-search text-xl text-gray-light"
          mandatory
        >
        </AutocompleteNewSolutip>
          <InputDisabled 
          v-else
          label="Approval 1" 
          value="John Doe"
        />
      </template>
    </div>
  </div>
</template>

<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'

export default {
  name: "Approval",
  props: ['disabled'],
  data(){
    return {
      approval: {
        approval1: '',
        approval2: ''
      },
      errorFileType: false,
      approvalList: []
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods: {
    focusApproval() {
      this.approvalList = [
        { fullName: 'Testing' },
        { fullName: 'Testing 1' },
        { fullName: 'Testing 2' },
        { fullName: 'Testing 3' },
      ]
    },
    blurApproval1() {
      console.log('blur approval 1');
    },
    blurApproval2() {
      console.log('blur approval 2');
    },
    updateApproval(e) {
      this.approval.approval1 = e.fullName
    },
    inputSearch(e) {
      console.log('search', e)
    },
  },
  components:{
    AutocompleteNewSolutip,
    InputDisabled
  }
}
</script>