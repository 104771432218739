<template>
  <div class="my-6">
    <div class="flex justify-between mb-6" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'items-center flex-row'">
      <h1 class="text-xl font-medium">SKT & LOI</h1>
      <SktLoiSecurityCode :class="projectDetailContainerWidth < 640 ? 'mt-4' : ''" v-if="spv1and2" />
    </div>
    <ApprovalTable/>
    <InformationDetails/>
    <div>
      <div class="flex justify-between mb-3.5">
        <div class="flex items-center">
          <span class="mr-6 text-lg icon-file-text text-gray"></span>
          <h5 class="text-gray-dark">SKT</h5>
        </div>
        <div class="flex items-center">
          <p :class="!collapse.skt ? 'rotate-180' : 'rotate-0'" @click="changeCollapse('skt')"
            class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
        </div>
      </div>
      <CardVendorSkt :submitted="true" v-if="!collapse.skt"/>
    </div>
    <div class="pt-6 border-t border-gray-lightest">
      <div class="flex justify-between mb-3.5">
        <div class="flex items-center">
          <span class="mr-6 text-lg icon-file-text text-gray"></span>
          <h5 class="text-gray-dark">LOI</h5>
        </div>
        <div class="flex items-center">
          <p :class="!collapse.loi ? 'rotate-180' : 'rotate-0'" @click="changeCollapse('loi')"
            class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
        </div>
      </div>
      <CardLoi :submitted="true" v-if="!collapse.loi"/>
    </div>
  </div>
</template>

<script>
import ApprovalTable from '@/core/components/rfp/sktLoi/ApprovalTable.vue'
import InformationDetails from '@/core/components/rfp/sktLoi/InformationDetails.vue'
import CardVendorSkt from '@/core/components/rfp/sktLoi/CardVendorSkt.vue'
import CardLoi from '@/core/components/rfp/sktLoi/CardLoi.vue'
import SktLoiSecurityCode from '@/core/components/rfp/sktLoi/SktLoiSecurityCode.vue'

export default {
  name: 'SktLoiDetails',
  data() {
    return {
      collapse: {
        skt: false,
        loi: false,
      },
      spv1and2: true
    }
  },
  computed: {
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods: {
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key] 
    }
  },
  components: {
    ApprovalTable,
    InformationDetails,
    CardVendorSkt,
    CardLoi,
    SktLoiSecurityCode
  }
}
</script>