<template>
  <div>
    <Information />
    <CardVendorSkt />
    <Approval />
  </div>
</template>

<script>
import Information from '@/core/components/rfp/sktLoi/Information.vue'
import CardVendorSkt from '@/core/components/rfp/sktLoi/CardVendorSkt.vue';
import Approval from '@/core/components/rfp/sktLoi/Approval.vue'
export default {
  name: "CreateSkt",
  components: {
    Information,
    CardVendorSkt,
    Approval
}
}
</script>