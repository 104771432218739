<template>
  <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
    <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse">
      <div class="flex items-center">
        <span class="mr-4 icon-list"></span>
        <h5 class="text-lg font-medium text-gray-darkest">NEGOTIATION RESULTS &nbsp;</h5>
        <span v-if="!readOnly" class="text-error">*</span>
      </div>
      <div>
        <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.negoResults ? 'rotate-180' : 'rotate-0'"></p>
      </div>
    </div>
    <div v-show="collapse.negoResults" v-for="vendor, i in vendorCandidates" :key="i">
      <div class="w-full mb-3 border rounded-lg border-gray-lightest">
        <div class="w-full mb-1 p-3.5">
          <h5 class="mb-1 text-sm font-medium text-tertiary">Vendor #</h5>
          <h5 class="text-base font-medium text-gray-darkest">{{ vendor.vendorName }}</h5>
          <div v-if="vendor.localPartners.length > 0" class="w-full pl-4 mt-3 border-l border-gray-light">
            <h5 class="mb-1 text-sm font-medium text-tertiary">Local Partner #1</h5>
            <h5 class="text-sm font-medium text-gray-darkest">{{ vendor.localPartners[0].vendorName }}</h5>
          </div>
        </div>
      <ResultTable :readOnly="readOnly" :usd="vendor.usd" />
      <div class="col-span-2 p-3.5 my-1">
          <div class="flex items-center">
            <label class="block mb-1 text-sm text-gray">
              Term of Payment
            </label>
          </div>
          <div class="">
          <TextareaSolutip disabled class="text-gray" value="Ini contoh field text area yang sudah terisi value." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultTable from '@/core/components/rfp/procurementApproval/ResultTable.vue'
import TextareaSolutip from '@/core/components/custom/Textarea.vue'

export default {
  name: "NegotiationResult",
  data(){
    return {
      vendorCandidates: [
        {
          vendorName: 'PT. Lorem Ipsum',
          localPartners: [{vendorName: 'PT. Satu Indonesia'}]
        },
        { vendorName: 'PT. ASD', localPartners: [], usd: true }
      ]
    }
  },
  props: ['collapse', 'readOnly'],
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods:{
    changeCollapse() {
      this.$emit('changeCollapse')
    },
  },
  components:{
    ResultTable,
    TextareaSolutip,
  }
}
</script>