<template>
  <div>
    <ButtonGista type="secondary" class="w-full" color="primary" @click="changeIsOpen(true)">Save As Draft</ButtonGista>
    <ModalConfirm 
      title="Simpan sebagai Draft" 
      message="Anda dapat melanjutkan draft di tab ‘Approval’" 
      icon="saved" textButton="Save As Draft" 
      :isOpen="isOpen" 
      @changeIsOpen="changeIsOpen($event)" 
      @click="clickSaveAsDraft">
    </ModalConfirm>
  </div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'

export default {
  name: 'SaveAsDraftApprover',
  props: ['isOpen'],
  methods: {
    changeIsOpen(val) {
      this.$emit('clickToggle', val)
    },
    clickSaveAsDraft() {
      this.$emit('click')
    }
  },
  components: {
    ModalConfirm
  }
}
</script>