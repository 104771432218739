<template>
  <div>
    <ButtonGista type="secondary" class="w-full" color="primary" @click="changeIsOpen(true)">Save As Draft</ButtonGista>
    <ModalConfirm 
      title="Simpan sebagai Draft" 
      message="Anda dapat melanjutkan draft di tab ‘SKT & LOI’" 
      icon="saved" textButton="Save As Draft" 
      :isOpen="isOpen" 
      @changeIsOpen="changeIsOpen($event)" 
      @click="clickSaveAsDraft">
    </ModalConfirm>
  </div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'

export default {
  name: 'SaveAsDraftSktLoi',
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    changeIsOpen(val) {
      this.isOpen = val
    },
    clickSaveAsDraft() {
      this.$emit('click')
    }
  },
  components: {
    ModalConfirm
  }
}
</script>