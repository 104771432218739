<template>
	<div>
		<ButtonGista @click="toggle"
			color="success"
			:disabled="proposalInformation?.isCanceled"
			customClass="w-full">
			Approve</ButtonGista>
		<GistaModal 
      :value="isOpen"
			width="2/3"
			@input="toggle">
			<div class="px-6 py-6">
				<div class="flex justify-center mb-6">
					<h4 class="text-3xl font-semibold">Setujui Proposal</h4>
				</div>
				<div class="border border-gray-lightest px-6 py-3.5 rounded-xl mb-4 w-full">
					<div class="flex flex-col items-center w-full sm:flex-row">
						<div class="w-full sm:w-2/3 pr-2.5 sm:border-r border-gray-lightest">
							<div class="flex mb-1">
								<h6 class="text-secondary-blue">{{ isLocalPartner? 'Local Partner' : 'Vendor' }} #{{qualification?.sequence}}</h6>
							</div>
							<h5 class="text-sm break-words">{{qualification?.vendorName}}</h5>
							<p class="mb-1 break-words text-gray-dark">{{qualification?.vendorEmail}}</p>
							<div class="line-horizontal"></div>
							<div class="flex flex-wrap items-center">
								<p class="text-gray-dark">Required Documents: &nbsp;</p>
								<h5 v-if="qualification.isCompletedRequiredDocuments" class="text-sm text-success">Completed</h5>
							</div>
						</div>
						<div class="flex items-center justify-start w-full pt-3 mt-3 border-t sm:justify-center sm:border-none border-gray-lightest sm:mt-0 sm:pt-0 sm:w-1/3 sm:px-3">
							<GistaCheckbox @change="changeCheckBox($event, 'isPerluPoc')" :modelValue="isPerluPoc">Perlu POC (Proof Of Concept)</GistaCheckbox>

						</div>
					</div>
				</div>

				<div class="flex justify-end">

					<ButtonGista @click="clickApprove"
						color="success"
						customClass="w-full sm:w-60">
						Approve</ButtonGista>

				</div>
			</div>
		</GistaModal>
		
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_APPROVE_PROPOSAL } from '@/core/constant/successMessage'

export default {
	name: 'ProposalApprove',
	props: ['qualification', 'index', 'isLocalPartner'],
	data() {
		return {
			isOpen: false,
			isPerluPoc: false,
		}
	},
	computed: {
		proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
			this.isPerluPoc = false
		},
		changeCheckBox(e) {
			this.isPerluPoc = e
		},
		async clickApprove() {
			try {
				//show loading
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('qualification/putQualificationProposalApproval', { vendorCandidateId: this.qualification.vendorCandidateId, isPerluPoc: this.isPerluPoc } )
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false
					//show modal success proposal send approve
					this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVE_PROPOSAL };
					this.isOpen = false;
					this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
					this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				console.log(error);
				//loading out
				this.modal.modalLoading = false
				//show modal error proposal send approve
				this.modal.modalError = { value:true, title: error.title, message: error.message, traceid: error.traceid }
			}

		}
	},
}

</script>