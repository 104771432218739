<template>
  <div>
    <InformationLoi/>
    <CardLoi/>
    <Approval :disabled="true"/>
  </div>
</template>

<script>
import CardLoi from '@/core/components/rfp/sktLoi/CardLoi.vue'
import Approval from '@/core/components/rfp/sktLoi/Approval.vue'
import InformationLoi from '@/core/components/rfp/sktLoi/InformationLoi.vue'


export default {
    name: "CreateLoi",
    data() {
        return {};
    },
    components: { 
      CardLoi,
      Approval,
      InformationLoi
    }
}
</script>