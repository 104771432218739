<template>
	<div>
        <span 
            class="text-2xl cursor-pointer icon-arrow-left text-gray" 
            @click="toggle"
        ></span>
        <gista-modal :value="isOpen"
            customClass="max-w-2xl bg-white"
            width="2/3"
			scrollable
            @input="toggle">
            <div class="w-full p-6">
                <div class="flex justify-center p-3 mb-6">
                    <h1 class="text-xl text-gray">{{ edit ? editText.title : createText.title }}</h1>
                </div>
                <div class="flex justify-center p-3 mb-6">
                    <p class="text-center text-gray">{{ edit ? editText.sub : createText.sub }}</p>
                </div>
                <div class="flex justify-center flex-none">
                    <button-gista type="primary"
                        customClass="w-full sm:w-56"
                        color="error"
                        @click="click">
                        Yes
                    </button-gista>
                </div>
            </div>
		</gista-modal>
	</div>
</template>

<script>
export default {
	name: "CancelCreateApproval",
	props: ['edit'],
	data() {
		return {
			isOpen: false,
            editText: {
                title: 'Batalkan Perubahan',
                sub: 'Perubahan tidak akan tersimpan'
            },
            createText: {
                title: 'Batalkan Pembuatan Persetujuan',
                sub: 'Informasi yang diisi akan hilang, apakah Anda yakin?'
            }
		}
	},
    computed:{
        projectDetailContainerWidth() {
            return this.$store.state.width.projectDetailContainerWidth
        },
    },
	methods: {
		click() {
			this.toggle()
			this.$emit("click")
		},
		toggle() {
			this.isOpen = !this.isOpen
		},
	},
	beforeDestroy() {
        if(this.isOpen) this.toggle()
    }
}
</script>